import React from "react";
import { Row, Grid, Card, Typography } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import RunningEventList from "../../../components/sport-register/running-event-list";
import AppLayout from "../../../layout/app-layout";
import { useNavigate } from "react-router-dom";
import MenuButton from "../../../components/menu-button";
import CategoryButton from "../../../components/sport-register/category-button";
import FormControl from "../../../components/sport-register/form-control";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../../context/app-provider";
import { GetPublicRelations, GetPublicRelationsUser } from "../../../services/fetch-public-relations";
import RecommendCard from "../recommend-card";

const { useBreakpoint } = Grid;

const SpiritualPage = ({ user }) => {
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [activeButton, setActiveButton] = React.useState("spiritual");
  const [publicRelationsData, setPublicRelationsData] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [selectedProvince, setSelectedProvince] = React.useState("");
  const [selectedMonth, setSelectedMonth] = React.useState("");
  const { language } = useAppContext();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const loadEvents = async () => {
      try {
        setLoading(true);
        const response = await GetPublicRelationsUser("spiritual", language);

        const formattedData = Object.entries(response).map(([id, details]) => ({
          id,
          ...details,
        }));

        setPublicRelationsData(formattedData);
      } catch (error) {
        console.error("Error fetching public relations data:", error);
      } finally {
        setLoading(false);
      }
    };

    loadEvents();
  }, [language]);

  const filteredPublicRelationsData = publicRelationsData?.filter((publicRelations) => {
    return (
      (publicRelations?.companyName?.toLowerCase().includes(searchTerm) ||
        publicRelations?.name?.toLowerCase().includes(searchTerm) ||
        publicRelations?.tags?.some((tag) => tag.toLowerCase().includes(searchTerm))) &&
      (selectedProvince === "" || publicRelations.province === selectedProvince)
    );
  });

  return (
    <AppLayout
      currentPage="หน้าแรก > วันเดย์ทริป"
      GetIconNav={<HomeOutlined style={{ fontSize: "24px" }} />}
      handleNavigate={() => navigate("/home")}
      screens={screens}
      user={user}
    >
      {/* <RecommendCard t={t} navigate={navigate} /> */}
      <div style={{ backgroundColor: "#4B3221", padding: "24px" }}>
        <Row gutter={[16, 16]} align="middle">
          <CategoryButton t={t} activeButton={activeButton} />
          <FormControl
            t={t}
            screens={screens}
            setSearchTerm={setSearchTerm}
            setSelectedProvince={setSelectedProvince}
            activeButton={activeButton}
          />
        </Row>
      </div>

      <RunningEventList
        loading={loading}
        publicRelationsData={filteredPublicRelationsData}
        setSearchTerm={setSearchTerm}
        setSelectedProvince={setSelectedProvince}
        t={t}
        activeButton={activeButton}
        screens={screens}
      />
      {/* <div
        style={{
          backgroundColor: "#FCBE95",
          padding: "24px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh", // Full viewport height
        }}
      >
        <Card
          style={{ maxWidth: "100%", width: "100%", textAlign: "center", backgroundColor: "#FFFFFF" }}
          bordered={false}
        >
          <div
            style={{
              position: "relative",
              width: "100%",
              paddingTop: "106.2%",
              boxShadow: "0 2px 8px 0 rgba(63,69,81,0.16)",
              marginTop: "1.6em",
              marginBottom: "0.9em",
              overflow: "hidden",
              borderRadius: "8px",
              willChange: "transform",
            }}
          >
            <iframe
              loading="lazy"
              style={{
                position: "absolute",
                width: "100%",
                height: "100%", // Ensure iframe takes full height of the container
                top: 0,
                left: 0,
                border: "none",
                padding: 0,
                margin: 0,
              }}
              src="https://www.canva.com/design/DAGSyrk8MA8/3ZguV0lqTgi2z2Y9K9nxsg/view?embed"
              allowFullScreen
              title="Canva Design"
            />
          </div>
        </Card>
      </div> */}
    </AppLayout>
  );
};

export default SpiritualPage;
