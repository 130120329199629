import { Form, Select } from "antd";

const provincesTH = [
  "กรุงเทพมหานคร",
  "กระบี่",
  "กาญจนบุรี",
  "กาฬสินธุ์",
  "กำแพงเพชร",
  "ขอนแก่น",
  "จันทบุรี",
  "ฉะเชิงเทรา",
  "ชลบุรี",
  "ชัยนาท",
  "ชัยภูมิ",
  "ชุมพร",
  "เชียงราย",
  "เชียงใหม่",
  "ตรัง",
  "ตราด",
  "ตาก",
  "นครนายก",
  "นครปฐม",
  "นครพนม",
  "นครราชสีมา",
  "นครศรีธรรมราช",
  "นครสวรรค์",
  "นนทบุรี",
  "นราธิวาส",
  "น่าน",
  "บึงกาฬ",
  "บุรีรัมย์",
  "ปทุมธานี",
  "ประจวบคีรีขันธ์",
  "ปราจีนบุรี",
  "ปัตตานี",
  "พระนครศรีอยุธยา",
  "พังงา",
  "พัทลุง",
  "พิจิตร",
  "พิษณุโลก",
  "เพชรบุรี",
  "เพชรบูรณ์",
  "แพร่",
  "พะเยา",
  "ภูเก็ต",
  "มหาสารคาม",
  "มุกดาหาร",
  "แม่ฮ่องสอน",
  "ยโสธร",
  "ยะลา",
  "ร้อยเอ็ด",
  "ระนอง",
  "ระยอง",
  "ราชบุรี",
  "ลพบุรี",
  "ลำปาง",
  "ลำพูน",
  "เลย",
  "ศรีสะเกษ",
  "สกลนคร",
  "สงขลา",
  "สตูล",
  "สมุทรปราการ",
  "สมุทรสงคราม",
  "สมุทรสาคร",
  "สระแก้ว",
  "สระบุรี",
  "สิงห์บุรี",
  "สุโขทัย",
  "สุพรรณบุรี",
  "สุราษฎร์ธานี",
  "สุรินทร์",
  "หนองคาย",
  "หนองบัวลำภู",
  "อ่างทอง",
  "อำนาจเจริญ",
  "อุดรธานี",
  "อุตรดิตถ์",
  "อุทัยธานี",
  "อุบลราชธานี",

  // ... keep existing code (provinces array)
];

const provincesEN = [
  "Bangkok",
  "Krabi",
  "Kanchanaburi",
  "Kalasin",
  "Kamphaeng Phet",
  "Khon Kaen",
  "Chantaburi",
  "Chachoengsao",
  "Chonburi",
  "Chainat",
  "Chaiyaphum",
  "Chumphon",
  "Chiang Rai",
  "Chiang Mai",
  "Trang",
  "Trat",
  "Tak",
  "Nakhon Nayok",
  "Nakhon Pathom",
  "Nakhon Phanom",
  "Nakhon Ratchasima",
  "Nakhon Si Thammarat",
  "Nakhon Sawan",
  "Nonthaburi",
  "Narathiwat",
  "Nan",
  "Bueng Kan",
  "Buri Ram",
  "Pathum Thani",
  "Prachuap Khiri Khan",
  "Prachinburi",
  "Pattani",
  "Phra Nakhon Si Ayutthaya",
  "Phang Nga",
  "Phatthalung",
  "Phichit",
  "Phitsanulok",
  "Phetchaburi",
  "Phetchabun",
  "Phrae",
  "Phayao",
  "Phuket",
  "Maha Sarakham",
  "Mukdahan",
  "Mae Hong Son",
  "Yasothon",
  "Yala",
  "Roi Et",
  "Ranong",
  "Rayong",
  "Ratchaburi",
  "Lopburi",
  "Lampang",
  "Lamphun",
  "Loei",
  "Si Sa Ket",
  "Sakon Nakhon",
  "Songkhla",
  "Satun",
  "Samut Prakan",
  "Samut Songkhram",
  "Samut Sakhon",
  "Sa Kaeo",
  "Saraburi",
  "Singburi",
  "Sukhothai",
  "Suphan Buri",
  "Surat Thani",
  "Surin",
  "Nong Khai",
  "Nong Bua Lamphu",
  "Ang Thong",
  "Amnat Charoen",
  "Udon Thani",
  "Uttaradit",
  "Uthai Thani",
  "Ubon Ratchathani",
];

export const ProvinceSelectTH = () => {
  return (
    <Form.Item label="จังหวัด" name="province" rules={[{ required: true, message: "กรุณาเลือกจังหวัด" }]}>
      <Select
        showSearch
        placeholder="เลือกจังหวัด"
        optionFilterProp="children"
        filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
        options={provincesTH.map((province) => ({
          value: province,
          label: province,
        }))}
      />
    </Form.Item>
  );
};

export const ProvinceSelectEN = () => {
  return (
    <Form.Item label="จังหวัด" name="province" rules={[{ required: true, message: "กรุณาเลือกจังหวัด" }]}>
      <Select
        showSearch
        placeholder="เลือกจังหวัด"
        optionFilterProp="children"
        filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
        options={provincesEN.map((province) => ({
          value: province,
          label: province,
        }))}
      />
    </Form.Item>
  );
};
