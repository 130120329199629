import React from "react";
import { Upload, notification, Button, Form, Image } from "antd";
import { CameraOutlined, CloseOutlined } from "@ant-design/icons";

const ImageUpload = ({
  name,
  label,
  onFileChange,
  t,
  setImagePreviewTH,
  setImagePreviewEN,
  imageCoverValueTH,
  imageCoverValueEN,
  imageLogoValueTH,
  imageLogoValueEN,
  setFormDataTH,
  setFormDataEN,
  formDataEN,
  formDataTH,
  typeData,
}) => {
  const [fileList, setFileList] = React.useState([]);
  const [loadingCard, setLoadingCard] = React.useState(false);

  const handleClearImage = () => {
    setFileList([]);

    onFileChange(name, null);
    if (typeData === "imageCoverTH") {
      setImagePreviewTH(null);
      setFormDataTH({ ...formDataTH, imageCover: imageCoverValueTH });
    }
    if (typeData === "imageLogoTH") {
      setImagePreviewTH(null);
      setFormDataTH({ ...formDataTH, imageLogo: imageLogoValueTH });
    }
    if (typeData === "imageCoverEN") {
      setImagePreviewEN(null);
      setFormDataEN({ ...formDataEN, imageCover: imageCoverValueEN });
    }
    if (typeData === "imageLogoEN") {
      setImagePreviewEN(null);
      setFormDataEN({ ...formDataEN, imageLogo: imageLogoValueEN });
    }
  };

  const beforeUpload = (file) => {
    const isValidSize = file.size / 1024 / 1024 < 3;
    if (!isValidSize) {
      notification.error({
        message: t("sport-register-form-page.title-40"),
        description: t("sport-register-form-page.title-41"),
      });
      setFileList([]);
      return Upload.LIST_IGNORE;
    }

    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      notification.error({
        message: t("sport-register-form-page.title-42"),
        description: t("sport-register-form-page.title-43"),
      });
      setFileList([]);
      return Upload.LIST_IGNORE;
    }

    return isValidSize && isJpgOrPng;
  };

  const handleFileChange = async ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (newFileList.length > 0) {
      const file = newFileList[0].originFileObj;
      const imageURL = URL.createObjectURL(file);

      onFileChange({
        name,
        imageURL,
        rawData: null,
      });

      try {
        const reader = new FileReader();
        reader.readAsDataURL(newFileList[0].originFileObj);

        reader.onload = async (e) => {
          const rawData = e.target.result;

          onFileChange({
            name,
            imageURL,
            rawData,
          });

          // ตั้งค่าค่าของฟิลด์ที่เป็นภาพในฟอร์ม
          if (typeData === "imageCoverTH") {
            setFormDataTH((prevData) => ({ ...prevData, imageCover: rawData }));
          } else if (typeData === "imageLogoTH") {
            setFormDataTH((prevData) => ({ ...prevData, imageLogo: rawData }));
          } else if (typeData === "imageCoverEN") {
            setFormDataEN((prevData) => ({ ...prevData, imageCover: rawData }));
          } else if (typeData === "imageLogoEN") {
            setFormDataEN((prevData) => ({ ...prevData, imageLogo: rawData }));
          }
        };

        reader.onerror = () => {
          console.error("Error reading file");
        };
      } catch (error) {
        console.error("Error during file processing:", error);
      }
    } else {
      onFileChange({
        name,
        imageURL: null,
        fileURL: null,
      });
    }
  };

  return (
    <Form.Item
      name={name}
      label={label}
      layout="horizontal"
      rules={[{ required: true, message: "กรุณาอัปโหลดรูปภาพ" }]}
    >
      {fileList.length > 0 ? (
        <div style={{ position: "relative" }}>
          <Image
            src={URL.createObjectURL(fileList[0].originFileObj)}
            alt="Image preview"
            style={{ width: 300, height: "auto" }}
          />
          <Button
            type="text"
            icon={<CloseOutlined />}
            onClick={handleClearImage}
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              border: "none",
            }}
          />
        </div>
      ) : (
        <Upload.Dragger
          listType="picture"
          name="files"
          maxCount={1}
          beforeUpload={beforeUpload}
          onChange={handleFileChange}
          showUploadList={false}
          customRequest={() => {}}
        >
          <p className="ant-upload-drag-icon">
            <CameraOutlined />
          </p>
          <p className="ant-upload-text">{t("sport-register-form-page.title-26")}</p>
        </Upload.Dragger>
      )}
    </Form.Item>
  );
};

export default ImageUpload;
