import React from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Typography,
  Upload,
  Image,
  notification,
  Space,
  Select,
  Flex,
  Form,
  Input,
  Spin,
  Modal,
} from "antd";
import { CameraOutlined, CloseOutlined, DownloadOutlined } from "@ant-design/icons";
import ExcelUploader from "./excel-upload";
import ExcelDataTable from "./data-from-excel";
import { fetchEventById, postImageSlip, pushNewMember } from "../../services/fetch-events";
import dayjs from "dayjs";
import { useAppContext } from "../../context/app-provider";
import { OverlayLoading } from "../loading-spin";

const { Text, Title } = Typography;

const FormRegisterForFriend = ({
  t,
  navigate,
  activeButton,
  user,
  qrCodeData,
  eventId,
  screens,
  loading,

  setLoading,

  handleTabRegister,
  buttonInCardStyles,
}) => {
  const [excelData, setExcelData] = React.useState([]);
  const [fileListExcel, setFileListExcel] = React.useState([]);
  const [fileList, setFileList] = React.useState([]);
  const [events, setEvents] = React.useState([]);
  const { language } = useAppContext();
  const [groupName, setGroupName] = React.useState("");
  const [loadingCard, setLoadingCard] = React.useState(false);
  const [loadingButton, setLoadingButton] = React.useState(false);

  const totalPrice = excelData[0]?.totalPrice || 0;
  const excelFileUrl = events?.excelForm;

  const handleClick = () => {
    if (excelFileUrl) {
      window.open(excelFileUrl, "_blank"); // เปิดไฟล์ Excel ในแท็บใหม่
    } else {
      // คุณสามารถแสดงข้อความเตือนหากไม่พบลิงก์
      alert(t("sport-register-form-page.button-download-excel"));
    }
  };

  React.useEffect(() => {
    const getEventData = async () => {
      const { eventData } = await fetchEventById(eventId, language);
      setEvents(eventData);
      setLoading(false);
    };

    getEventData();
  }, [eventId, language]);

  const handleExcelData = (data) => {
    setExcelData(data);
  };

  const handleSubmit = async () => {
    setLoadingButton(true);

    try {
      if (!groupName) {
        notification.error({
          message: t("sport-register-form-page.title-66"),
          description: t("sport-register-form-page.title-67"),
        });
        setLoading(false);
        return;
      }

      let fileURL = "";
      if (fileList.length === 0) {
        notification.error({
          message: t("sport-register-form-page.title-35"),
          description: t("sport-register-form-page.title-36"),
        });
        return;
      }

      const currentDate = dayjs();
      const formattedDate = currentDate.format("DD/MM/YYYY");
      const formattedDateStorage = currentDate.format("DD-MM-YYYY");
      const formattedTime = currentDate.format("HH:mm:ss");

      let qrImage = "";

      if (fileList.length > 0) {
        await new Promise(async (resolve, reject) => {
          const fileName = `${eventId}/paymentSlip/${user?.email}_${formattedDateStorage}_${formattedDateStorage}`;
          const reader = new FileReader();
          reader.readAsDataURL(fileList[0].originFileObj);
          reader.onload = async (e) => {
            const rawData = e.target.result;
            const fileURL = await postImageSlip(fileName, rawData);
            if (fileURL) {
              qrImage = fileURL;
              resolve(); // อัปโหลดสำเร็จ
            } else {
              console.error("Failed to upload the image slip.");
              reject(new Error("Failed to upload image")); // อัปโหลดไม่สำเร็จ
            }
          };
          reader.onerror = () => {
            reject(new Error("Error reading file")); // ข้อผิดพลาดในการอ่านไฟล์
          };
        });
      }

      if (excelData.length === 0) {
        notification.error({
          message: t("sport-register-form-page.title-68"),
          description: t("sport-register-form-page.title-69"),
        });
        setLoading(false);
        return;
      }

      const duplicatePhoneDetails = excelData
        .map((data, index) => {
          const duplicateIndex = excelData.findIndex((item, i) => item.phone === data.phone && i !== index);
          if (duplicateIndex !== -1) {
            return `${t("sport-register-form-page.title-64")} ${index + 1} ${t(
              "sport-register-form-page.phone-duplicate-with"
            )} ${t("sport-register-form-page.title-64")} ${duplicateIndex + 1}`;
          }
          return null;
        })
        .filter(Boolean);

      if (duplicatePhoneDetails.length > 0) {
        const duplicateMessage = duplicatePhoneDetails.map((detail, index) => (
          <span key={index}>
            {detail}
            <br />
          </span>
        ));

        Modal.warning({
          title: t("sport-register-form-page.title-81"),
          content: <div style={{ fontSize: "24px" }}>{duplicateMessage}</div>,
          width: 800,
          centered: true,
        });

        return;
      }

      const memberData = excelData.map((fData) => ({
        date: formattedDate,
        time: formattedTime,
        uid: user?.uid,
        title: fData.title,
        address: fData.address,
        birthDate: fData.birthDate,
        firstName: fData.firstName,
        lastName: fData.lastName,
        phone: fData.phone.toString(), // Convert phone to string
        note: fData.note || "",
        idPass: fData.idPass || "",
        otherContact: fData.otherContact || "",
        bloodGroup: fData.bloodGroup || "",
        // otherPhone: fData.otherPhone || "",
        qrImage: qrImage,
        shirtSize: fData.shirtSize,
        raceOption: fData.raceOption,
        ageGroup: fData.ageGroup,
        delivery: fData.delivery || false,
        lifeInsurance: fData.lifeInsurance || false,
        totalPrice: fData.totalPrice,
        status: "pending",
        refAcc: user?.email,
        groupName: groupName,
      }));

      const response = await pushNewMember(`${activeButton}`, eventId, memberData);

      if (response === "Success") {
        setLoadingCard(true);
        setFileListExcel([]);
        setGroupName("");
        setFileList([]);
        setExcelData([]);
        navigate(`/${language}/sport-register/${activeButton}/member/${eventId}`);

        notification.open({
          message: null,
          description: (
            <div style={{ textAlign: "center" }}>
              <img
                src="/picture-success.jpg"
                alt="Success"
                style={{
                  width: "400px",
                  height: "auto",
                  marginBottom: "16px",
                  borderRadius: "8px",
                }}
              />
            </div>
          ),
          style: {
            width: "auto",
            background: "transparent",
            boxShadow: "none",
          },
          duration: 3,
          placement: "top",
        });
      } else if (response !== "Success") {
        Modal.error({
          title: t("sport-register-form-page.title-77"),
          content: (
            <div style={{ fontSize: "24px" }}>
              {t("sport-register-form-page.title-78")} <strong>{response.join(", ")}</strong>
              <br />
              {t("sport-register-form-page.title-79")}
              {t("sport-register-form-page.title-80")}
            </div>
          ),
          width: 800,
          centered: true,
          onOk() {},
        });
      }
    } catch (error) {
      console.log("🚀 ~ handleSubmit ~ error:", error)
      notification.error({
        message: t("sport-register-form-page.title-38"),
        description: t("sport-register-form-page.title-39"),
      });
    } finally {
      setLoadingCard(false);
      setLoadingButton(false);
    }
  };

  if (loadingCard) {
    return <OverlayLoading />;
  }

  const handleClearImage = () => {
    setFileList([]);
  };

  const beforeUpload = (file) => {
    const isValidSize = file.size / 1024 / 1024 < 3;
    if (!isValidSize) {
      notification.error({
        message: t("sport-register-form-page.title-40"),
        description: t("sport-register-form-page.title-41"),
      });
      setFileList([]);
      return Upload.LIST_IGNORE;
    }

    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      notification.error({
        message: t("sport-register-form-page.title-42"),
        description: t("sport-register-form-page.title-43"),
      });
      setFileList([]);
      return Upload.LIST_IGNORE;
    }

    return isValidSize && isJpgOrPng;
  };

  return (
    <>
      <Card
        style={{
          backgroundColor: "rgba(94, 114, 235, 0.2)",
          borderRadius: 0,
          borderTop: "15px solid ",
          borderColor: "#130C63",
          borderLeft: "none",
          borderRight: "none",
          borderBottom: "none",
        }}
      >
        {screens.xs ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="tab-select-form-group"
          >
            <Select defaultValue="3" onChange={handleTabRegister} style={{ width: "50%" }}>
              <Select.Option value="1">{t("sport-register-form-page.header-sub-card-button-1")}</Select.Option>
              <Select.Option value="2">{t("sport-register-form-page.header-sub-card-button-2")}</Select.Option>
              <Select.Option value="3">{t("sport-register-form-page.header-sub-card-button-3")}</Select.Option>
              {events?.onlyShirt !== undefined ? (
                <Select.Option value="4">{t("sport-register-form-page.header-sub-card-button-4")}</Select.Option>
              ) : null}
            </Select>
          </div>
        ) : (
          <Row gutter={[16, 32]}>
            <Col xs={4} sm={5} md={4} lg={4} xl={3} xxl={3} offset={3}>
              <Button type="text" style={buttonInCardStyles[0]} onClick={() => handleTabRegister("1")}>
                <Space direction="vertical" align="center">
                  <Text strong style={{ margin: 0, color: "#FFFFFF" }}>
                    {t("sport-register-form-page.header-sub-card-button-1")}
                  </Text>
                </Space>
              </Button>
            </Col>
            <Col xs={6} sm={6} md={4} lg={4} xl={3} xxl={3}>
              <Button type="text" style={buttonInCardStyles[1]} onClick={() => handleTabRegister("2")}>
                <Space direction="vertical" align="center">
                  <Text strong style={{ margin: 0, color: "#FFFFFF" }}>
                    {t("sport-register-form-page.header-sub-card-button-2")}
                  </Text>
                </Space>
              </Button>
            </Col>
            <Col xs={7} sm={7} md={4} lg={4} xl={3} xxl={3}>
              <Button type="text" style={buttonInCardStyles[2]} onClick={() => handleTabRegister("3")}>
                <Space direction="vertical" align="center">
                  <Text strong style={{ margin: 0, color: "#FFFFFF" }}>
                    {t("sport-register-form-page.header-sub-card-button-3")}
                  </Text>
                </Space>
              </Button>
            </Col>
            <Col xs={7} sm={7} md={4} lg={4} xl={3} xxl={3}>
              {events?.onlyShirt !== undefined ? (
                <Button type="text" style={buttonInCardStyles[0]} onClick={() => handleTabRegister("4")}>
                  <Space direction="vertical" align="center">
                    <Text strong style={{ margin: 0, color: "#FFFFFF" }}>
                      {t("sport-register-form-page.header-sub-card-button-4")}
                    </Text>
                  </Space>
                </Button>
              ) : null}
            </Col>
          </Row>
        )}
        <Card
          style={{
            maxWidth: "1280px",
            width: "100%",
            margin: "0 auto",
            borderTop: "15px solid",
            borderColor: "#2DB2AD",
            borderLeft: "none",
            borderRight: "none",
            borderBottom: "none",
            borderRadius: "40px",
            padding: "20px",
            position: "relative",
          }}
        >
          <Flex justify="end" style={{ paddingBottom: 24 }}>
            <Button
              type="primary"
              shape="round"
              icon={<DownloadOutlined />}
              style={{ backgroundColor: "#2DB2AD", borderColor: "#2DB2AD" }} // เปลี่ยนสีพื้นหลังและขอบ
              onClick={handleClick}
            >
              {t("sport-register-form-page.title-70")}
            </Button>
          </Flex>
          <Row justify="space-around" style={{ paddingBottom: 24 }}>
            <Form>
              <Form.Item
                label={t("sport-register-form-page.title-11")}
                name="groupName"
                rules={[
                  {
                    required: true,
                    message: t("sport-register-form-page.title-66"),
                  },
                ]}
              >
                <Input
                  value={groupName}
                  onChange={(e) => setGroupName(e.target.value)}
                  placeholder={t("sport-register-form-page.title-66")}
                />
              </Form.Item>
            </Form>
            <ExcelUploader
              t={t}
              events={events}
              eventId={eventId}
              user={user}
              onDataUpload={handleExcelData}
              fileListExcel={fileListExcel}
              setExcelData={setExcelData}
              setFileListExcel={setFileListExcel}
              loading={loading}
              setLoading={setLoading}
            />
          </Row>
          {loading ? (
            <div style={{ textAlign: "center", padding: "20px" }}>
              <Spin tip={t("sport-register-form-page.title-71")} />
            </div>
          ) : (
            excelData.length > 0 && <ExcelDataTable excelData={excelData} events={events} />
          )}
        </Card>
        <Card
          style={{
            maxWidth: "1280px",
            width: "100%",
            margin: "0 auto",
            borderRadius: "40px",
            padding: "20px 40px 20px 40px",
            marginTop: "20px",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
          }}
        >
          <Row justify="center" align="middle" gutter={[32, 32]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8} style={{ textAlign: "center" }}>
              <Image src={qrCodeData?.qrPayment1} />
            </Col>
            {/* <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8} style={{ textAlign: "center" }}>
              <Image src={qrCodeData?.qrPayment2} />
            </Col> */}
            <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={{ span: 6, offset: 2 }}>
              <Title level={4} style={{ margin: 0, textAlign: "center" }}>
                {t("sport-register-form-page.title-24")}
              </Title>
              <Title level={5} style={{ margin: 0, textAlign: "center" }}>
                {t("sport-register-form-page.title-25")}
              </Title>
              <Form.Item name="qrImage" layout="horizontal">
                {fileList.length > 0 ? (
                  <div style={{ position: "relative" }}>
                    <Image src={URL.createObjectURL(fileList[0].originFileObj)} alt="Image preview" />
                    <Button
                      type="text"
                      icon={<CloseOutlined />}
                      onClick={handleClearImage}
                      style={{
                        position: "absolute",
                        top: 10,
                        right: 10,
                        backgroundColor: "rgba(255, 255, 255, 0.5)",
                        border: "none",
                      }}
                    />
                  </div>
                ) : (
                  <Upload.Dragger
                    listType="picture"
                    name="files"
                    maxCount={1}
                    beforeUpload={beforeUpload}
                    onChange={({ fileList: newFileList }) => setFileList(newFileList)}
                    showUploadList={false}
                    customRequest={() => {
                      /* no action required */
                    }}
                  >
                    <p className="ant-upload-drag-icon">
                      <CameraOutlined />
                    </p>
                    <p className="ant-upload-text">{t("sport-register-form-page.title-26")}</p>
                  </Upload.Dragger>
                )}
              </Form.Item>
              <Row justify="space-between" align="middle">
                <Col>
                  <Title level={5} style={{ margin: 0, color: "#038416" }}>
                    {t("sport-register-form-page.title-30")}
                  </Title>
                </Col>
                <Col>
                  <Title level={5} style={{ margin: 0, color: "#038416" }}>
                    {totalPrice} {t("sport-register-form-page.title-16")}
                  </Title>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
        <Flex justify="center" style={{ padding: "20px 0px 10px 0px" }}>
          <Button
            type="text"
            size="large"
            onClick={handleSubmit}
            loading={loadingButton}
            style={{ backgroundColor: "#038416", color: "#FFFFFF" }}
          >
            {t("sport-register-form-page.button-send")}
          </Button>
        </Flex>
      </Card>
    </>
  );
};

export default FormRegisterForFriend;
