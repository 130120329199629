import React from "react";
import { Card, Grid, Row, Typography } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import AdminLabel from "../../../../components/admin/admin-label";
import MenuButton from "../../../../components/menu-button";
import CategoryButton from "../../../../components/sport-register/category-button";
import AppLayoutAdmin from "../../../../layout/app-layout-admin";
import FormControl from "../../../../components/sport-register/form-control";
import RunningEventList from "../../../../components/sport-register/running-event-list";
import { useAppContext } from "../../../../context/app-provider";
import { useTranslation } from "react-i18next";
import AppLayout from "../../../../layout/app-layout";

const { useBreakpoint } = Grid;

const SportRegisterSportAdminPage = ({}) => {
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const { language } = useAppContext();
  const { t } = useTranslation();
  const [activeButton, setActiveButton] = React.useState("sport");

  const handleButtonClick = (type) => {
    setActiveButton(type);

    if (type === "run") {
      navigate(`/${language}/event/run`);
    } else if (type === "bicycle") {
      navigate(`/${language}/event/bicycle`);
    } else if (type === "sport") {
      navigate(`/${language}/event/sport`);
    }
  };

  return (
    <AppLayout currentPage="หน้าแรก" getIconNav={<HomeOutlined style={{ fontSize: "24px" }} />} screens={screens}>
      <AdminLabel t={t} screens={screens} />
      <MenuButton t={t} screens={screens} navigate={navigate} isSportRegisterPage={true} />
      <div style={{ backgroundColor: "#5E72EB", padding: "24px" }}>
        <Row gutter={[16, 16]} align="middle">
          <CategoryButton t={t} activeButton={activeButton} handleButtonClick={handleButtonClick} />
          <FormControl t={t} screens={screens} />
        </Row>
      </div>
      {/* <RunningEventList screens={screens} /> */}
    </AppLayout>
  );
};

export default SportRegisterSportAdminPage;
