import React from "react";
import {
  Card,
  Table,
  Input,
  Button,
  Space,
  Flex,
  Badge,
  Modal,
  Form,
  Image,
  DatePicker,
  Select,
  Grid,
  notification,
  Checkbox,
  message,
  Tooltip,
  Row,
  Col,
} from "antd";
import {
  SearchOutlined,
  ExclamationCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import AdminLabel from "../../../../components/admin/admin-label";
import MenuButton from "../../../../components/menu-button";
import AppLayoutAdmin from "../../../../layout/app-layout-admin";
import { useNavigate, useParams } from "react-router-dom";
import { deleteMember, fetchEventById, updateMember } from "../../../../services/fetch-events";
import DownloadExcel from "../../../../components/sport-register/excel-data-list-download";
import { useAppContext } from "../../../../context/app-provider";
import { useTranslation } from "react-i18next";
import AppLayout from "../../../../layout/app-layout";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const { confirm } = Modal;
const { Option } = Select;
const { useBreakpoint } = Grid;

const ManageCheckAndUpdateRunPage = ({}) => {
  const { id } = useParams();
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  const [filteredInfo, setFilteredInfo] = React.useState({});
  const [pagination, setPagination] = React.useState({ current: 1, pageSize: 10 }); // ตั้งค่าหน้าและขนาดของแต่ละหน้า
  const searchInput = React.useRef(null);
  const [events, setEvents] = React.useState([]);
  const [memberLists, setMemberLists] = React.useState([]);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [selectedMemberKey, setSelectedMemberKey] = React.useState("");
  const [form] = Form.useForm();
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [paymentStatus, setPaymentStatus] = React.useState(null);
  const [dataEdit, setDataEdit] = React.useState([]);
  const { language } = useAppContext();
  const { t } = useTranslation();

  const filtersAgeGroup =
    Object.values(events?.registerDetail || {})
      .flatMap((detail) => detail.typeDetail) // รวมเอา typeDetail จากทุก event เข้าด้วยกัน
      .filter((value, index, self) => self.indexOf(value) === index) // เอาค่าที่ไม่ซ้ำกัน
      .map((detail) => ({
        text: detail, // แสดงข้อความของประเภทอายุ
        value: detail, // ใช้ข้อความประเภทอายุเป็นค่าที่จะกรอง
      })) || [];

  React.useEffect(() => {
    if (events?.registerDetail) {
      const options = Object.keys(events.registerDetail).map((key) => {
        const raceDetail = events.registerDetail[key];
        return {
          key: key,
          name: key,
          value: key,
          distance: raceDetail.distance,
          delivery: raceDetail.delivery,
          lifeInsurance: raceDetail.lifeInsurance,
          price: raceDetail.price,
          shirtSize: raceDetail.shirtSize,
          shirtSizeMale: raceDetail.shirtSizeMale,
          shirtSizeFemale: raceDetail.shirtSizeFemale,
          shirtImage: raceDetail.shirtImage,
          typeDetailMale: raceDetail.typeDetailMale,
          typeDetailFemale: raceDetail.typeDetailFemale,
        };
      });
      setDataEdit(options);
    } else {
      console.error("registerDetail is undefined or null");
    }
  }, [events]); // แค่ตรวจสอบว่าเมื่อใดที่ events เปลี่ยนแปลง

  React.useEffect(() => {
    const getEventData = async () => {
      try {
        setLoading(true);
        const { eventData, members } = await fetchEventById(id, language);
        setEvents(eventData);
        setMemberLists(members);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getEventData();
  }, [id, language]);

  const shirtSize = Array.from(
    new Set(memberLists.map((memberList) => memberList.shirtSize).filter((name) => name))
  ).map((name) => ({
    text: (() => {
      switch (name) {
        case "XXL":
          return "2XL";
        case "XXXL":
          return "3XL";
        case "XXXXL":
          return "4XL";
        case "XXXXXL":
          return "5XL";
        case "XXS":
          return "2XS";
        default:
          return name;
      }
    })(),
    value: name,
  }));

  const groupNames = Array.from(
    new Set(memberLists.map((memberList) => memberList.groupName).filter((name) => name))
  ).map((name) => ({
    text: name,
    value: name,
  }));
  const refAcc = Array.from(new Set(memberLists.map((memberList) => memberList.refAcc).filter((name) => name))).map(
    (name) => ({
      text: name,
      value: name,
    })
  );

  const handleStatusChange = (value) => {
    setPaymentStatus(value);
  };

  const rowSelection = {
    onChange: (key, selectedRows) => {
      setSelectedRows(selectedRows);
    },
  };

  const applyStatusToSelected = async () => {
    if (selectedRows.length === 0) {
      message.warning("กรุณาเลือกแถวที่ต้องการเปลี่ยนสถานะการจ่ายเงิน");
      return;
    }
    setLoading(true);
    try {
      const updatedRows = selectedRows.map((row) => ({
        ...row,
        status: paymentStatus,
      }));

      const response = await updateMember("run", id, updatedRows);

      if (response) {
        setMemberLists((prevLists) =>
          prevLists.map((member) => {
            const updatedMember = updatedRows.find((row) => row.uid === member.uid);
            return updatedMember ? { ...member, status: updatedMember.status } : member;
          })
        );
        message.success("เปลี่ยนสถานะการจ่ายเงินเรียบร้อยแล้ว");
        setSelectedRows([]);
      } else {
        message.error("ไม่สามารถเปลี่ยนสถานะการจ่ายเงินได้");
      }
    } catch (error) {
      console.error("Error updating payment status:", error);
      message.error("เกิดข้อผิดพลาดในการเปลี่ยนสถานะการจ่ายเงิน");
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (record) => {
    setSelectedMemberKey(record?.id);
    setIsModalVisible(true);
    form.setFieldsValue({
      date: record.date,
      time: record.time,
      title: record.title,
      firstName: record.firstName,
      lastName: record.lastName,
      birthDate: record.birthDate,
      address: record.address,
      raceOption: record.raceOption,
      ageGroup: record.ageGroup,
      note: record.note,
      shirtSize: record.shirtSize,
      refAcc: record.refAcc,
      phone: record.phone,
      idPass: record.idPass,
      otherContact: record.otherContact,
      bloodGroup: record.bloodGroup,
      otherPhone: record.otherPhone,
      groupName: record.groupName,
      delivery: record.delivery,
      lifeInsurance: record.lifeInsurance,
      totalPrice: record.totalPrice,
      qrImage: record.qrImage,
      status: record.status,
      uid: record.uid,
    });
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleModalSubmit = () => {
    form.validateFields().then(async (values) => {
      // สร้าง array ของข้อมูลในรูปแบบ array of objects
      const updatedMemberData = {
        date: values.date,
        time: values.time,
        title: values.title,
        firstName: values.firstName,
        lastName: values.lastName,
        birthDate: values.birthDate,
        address: values.address,
        raceOption: values.raceOption,
        ageGroup: values.ageGroup,
        groupName: values.groupName,
        status: values.status,
        shirtSize: values.shirtSize,
        refAcc: values.refAcc,
        phone: values.phone,
        note: values.note,
        idPass: values.idPass,
        otherContact: values.otherContact,
        bloodGroup: values.bloodGroup,
        otherPhone: values.otherPhone,
        groupName: values.groupName || "",
        delivery: values.delivery,
        lifeInsurance: values.lifeInsurance,
        totalPrice: values.totalPrice,
        qrImage: values.qrImage,
        status: values.status,
        uid: values.uid,
      };
      setLoading(true);

      try {
        const response = await updateMember("run", id, [updatedMemberData]); // เปลี่ยนพารามิเตอร์ที่ถูกต้อง
        if (response) {
          // อัปเดต dataSource ใน UI
          setMemberLists((prevLists) =>
            prevLists.map((member) =>
              member.uid === updatedMemberData.uid && member.phone === updatedMemberData.phone
                ? { ...member, ...updatedMemberData }
                : member
            )
          );
          setIsModalVisible(false);
          form.resetFields();
        }
      } catch (error) {
        console.error("Error updating member:", error);
      } finally {
        setLoading(false);
      }
    });
  };

  const dataSource = memberLists;

  const hasIdPassInMemberLists = memberLists.some((member) => member.idPass);
  const hasOtherContactInMemberLists = memberLists.some((member) => member.otherContact);
  const hasBloodGroupInMemberLists = memberLists.some((member) => member.bloodGroup);
  const hasOtherPhoneInMemberLists = memberLists.some((member) => member.otherPhone);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        {dataIndex === "dateTime" ? (
          <div>
            <DatePicker.RangePicker
              style={{ marginBottom: 8, display: "block" }}
              format="DD-MM-YYYY"
              onChange={(dates) => {
                const [start, end] = dates || [];
                setSelectedKeys([
                  start ? start.startOf("day").format("DD-MM-YYYY") : "",
                  end ? end.endOf("day").format("DD-MM-YYYY") : "",
                ]);
              }}
            />
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ) : (
          <div>
            <Input
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: "block" }}
            />
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        )}
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) => {
      const recordDate = dayjs(record.date, "DD-MM-YYYY"); // แปลงเป็นวันที่
      const [start, end] = value; // ดึงค่าจาก selectedKeys

      // กรองตามช่วงวันที่
      if (start && end) {
        return (
          recordDate.isSameOrAfter(dayjs(start, "DD-MM-YYYY")) && recordDate.isSameOrBefore(dayjs(end, "DD-MM-YYYY"))
        );
      }

      return recordDate.isSameOrAfter(dayjs(value)); // กรองตามวันที่เริ่มต้น
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    if (dataIndex === "dateTime") {
      const [start, end] = selectedKeys;
      setFilteredInfo({ ...filteredInfo, dateTime: [start, end] });
    } else {
      setFilteredInfo({ ...filteredInfo, [dataIndex]: selectedKeys });
    }
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setFilteredInfo({});
  };

  const resetFilters = () => {
    setFilteredInfo({});
  };

  const handleTableChange = (newPagination, filters) => {
    setPagination(newPagination);
    setFilteredInfo(filters);
  };

  const showDeleteConfirm = (record) => {
    confirm({
      title: "Are you sure you want to delete this member?",
      icon: <ExclamationCircleOutlined />,
      content: `Member: ${record.firstName} ${record.lastName}`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(record);
      },
    });
  };

  const handleDelete = async (memberData) => {
    const refAcc = memberData?.refAcc;
    // สร้างข้อมูล memberData ที่ต้องการลบ
    const memberId = `${memberData?.phone}_${memberData?.uid}`;
    setLoading(true);
    try {
      const result = await deleteMember("run", id, memberId, refAcc);

      if (result) {
        setMemberLists((prevLists) => prevLists.filter((member) => `${member.phone}_${member.uid}` !== memberId));
        notification.success({
          message: "ลบข้อมูลสำเร็จ",
        });
      } else {
        console.error("Failed to delete member");
      }
    } catch (error) {
      console.error("Error deleting member:", error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "วันที่สมัคร",
      dataIndex: "date",
      key: "date",
      width: 100,
      fixed: "left",
      render: (text, record) => `${record.date} ${record.time}`, // รวมวันที่และเวลา
      sorter: (a, b) => {
        const dateA = dayjs(a.date, "DD-MM-YYYY"); // แปลงเป็นวันที่
        const dateB = dayjs(b.date, "DD-MM-YYYY"); // แปลงเป็นวันที่
        return dateA - dateB; // เปรียบเทียบเฉพาะวันที่
      },
      ...getColumnSearchProps("dateTime"),
      filters: [
        {
          text: "วันนี้",
          value: dayjs().startOf("day").format("DD-MM-YYYY"),
        },
        {
          text: "สัปดาห์นี้",
          value: dayjs().startOf("week").format("DD-MM-YYYY"),
        },
        {
          text: "เดือนนี้",
          value: dayjs().startOf("month").format("DD-MM-YYYY"),
        },
        {
          text: "เดือนที่แล้ว",
          value: dayjs().subtract(1, "month").startOf("month").format("DD-MM-YYYY"),
          endValue: dayjs().subtract(1, "month").endOf("month").format("DD-MM-YYYY"),
        },
      ],
      onFilter: (value, record) => {
        const recordDate = dayjs(record.date, "DD-MM-YYYY"); // แปลงเป็นวันที่
        const filterDate = dayjs(value, "DD-MM-YYYY"); // แปลงค่าที่กรองเป็นวันที่

        // กรองตามช่วงวันที่
        if (value === dayjs().subtract(1, "month").startOf("month").format("DD-MM-YYYY")) {
          const startOfLastMonth = dayjs().subtract(1, "month").startOf("month");
          const endOfLastMonth = dayjs().subtract(1, "month").endOf("month");
          return recordDate.isSameOrAfter(startOfLastMonth, "day") && recordDate.isSameOrBefore(endOfLastMonth, "day");
        }

        // กรองตามวันที่
        return recordDate.isSame(filterDate, "day");
      },
      filteredValue: filteredInfo.dateTime || null,
    },
    {
      title: "คำนำหน้า",
      dataIndex: "title",
      key: "title",
      filters: [
        { text: "นาย", value: "นาย" },
        { text: "นาง", value: "นาง" },
        { text: "นางสาว", value: "นางสาว" },
      ],
      onFilter: (value, record) => record.title === value,
      filteredValue: filteredInfo.title || null,
    },
    {
      title: "ชื่อ-สกุล",
      dataIndex: "firstName",
      key: "fullName",
      sorter: (a, b) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`),
      render: (text, record) => `${record.firstName} ${record.lastName}`, // รวมชื่อและนามสกุล
      ...getColumnSearchProps("fullName"),
      filteredValue: filteredInfo.fullName || null,
    },
    {
      title: "ชื่อรุ่น",
      dataIndex: "raceOption",
      key: "raceOption",
      filters:
        Object.keys(events?.registerDetail || {}).map((key) => ({
          text: events.registerDetail[key].eventName, // แสดงชื่อรุ่นจาก eventName
          value: key, // ใช้ key เป็นค่าที่จะกรอง
        })) || [],
      onFilter: (value, record) => record.raceOption === value,
      filteredValue: filteredInfo.raceOption || null,
    },
    {
      title: "รุ่นอายุ",
      dataIndex: "ageGroup",
      key: "ageGroup",
      filters: filtersAgeGroup, // ใช้ตัวกรองที่สร้างจาก typeDetail
      onFilter: (value, record) => record.ageGroup === value,
      filteredValue: filteredInfo.ageGroup || null,
    },
    {
      title: "ขนาดเสื้อ",
      dataIndex: "shirtSize",
      key: "shirtSize",
      width: 10,
      filters: shirtSize,
      onFilter: (value, record) => record.shirtSize === value,
      filteredValue: filteredInfo.shirtSize || null,
      render: (text) => {
        let displaySize = text; // ค่าที่จะแสดงเป็นค่าเริ่มต้น

        // แปลงค่า shirtSize ตามที่กำหนด
        switch (text) {
          case "XXL":
            displaySize = "2XL";
            break;
          case "XXXL":
            displaySize = "3XL";
            break;
          case "XXXXL":
            displaySize = "4XL";
            break;
          case "XXXXXL":
            displaySize = "5XL";
            break;
          case "XXS":
            displaySize = "2XS";
            break;
          default:
            break;
        }
        return displaySize; // คืนค่าที่แปลงแล้ว
      },
    },
    {
      title: "ค่าสมัคร",
      dataIndex: "totalPrice",
      key: "totalPrice",
    },
    {
      title: "รูปภาพ",
      dataIndex: "qrImage",
      key: "qrImage",
      render: (qrImage) => (
        <Image src={qrImage} alt="Payment" style={{ width: 100, height: 100, objectFit: "cover" }} />
      ),
    },
    {
      title: "วันเกิด",
      dataIndex: "birthDate",
      key: "birthDate",
      width: 10,
    },
    {
      title: "เบอร์โทรศัพท์",
      dataIndex: "phone",
      key: "phone",
      ...getColumnSearchProps("phone"),
      filteredValue: filteredInfo.phone || null,
    },
    {
      title: "ที่อยู่",
      dataIndex: "address",
      key: "address",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      onCell: () => ({
        style: {
          maxWidth: 100,
        },
      }),
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    },
    {
      title: "ชื่อทีม",
      dataIndex: "groupName",
      key: "groupName",
      filters: groupNames,
      onFilter: (value, record) => record.groupName === value,
      filteredValue: filteredInfo.groupName || null,
      render: (groupName) => (groupName ? groupName : "-"),
    },
    {
      title: "อีเมลผู้สมัคร",
      dataIndex: "refAcc",
      key: "refAcc",
      filters: refAcc,
      onFilter: (value, record) => record.refAcc === value,
      filteredValue: filteredInfo.refAcc || null,
      render: (refAcc) => (refAcc ? refAcc : "-"),
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      onCell: () => ({
        style: {
          maxWidth: 100,
        },
      }),
      render: (refAcc) => (
        <Tooltip placement="topLeft" title={refAcc}>
          {refAcc}
        </Tooltip>
      ),
    },
    ...(hasIdPassInMemberLists
      ? [
          {
            title: "เลขบัตรประชาชนหรือเลขพาสปอร์ต",
            dataIndex: "idPass",
            key: "idPass",
            // filters: idPass,
            // onFilter: (value, record) => record.idPass === value,
            filteredValue: filteredInfo.idPass || null,
            render: (idPass) => (idPass ? idPass : "-"),
            width: 100,
            ellipsis: {
              showTitle: false,
            },
            onCell: () => ({
              style: {
                maxWidth: 100,
              },
            }),
            render: (idPass) => (
              <Tooltip placement="topLeft" title={idPass}>
                {idPass}
              </Tooltip>
            ),
          },
        ]
      : []),
    ...(hasOtherContactInMemberLists
      ? [
          {
            title: "ช่องทางติดต่อออนไลน์ (Facebook/Line)",
            dataIndex: "otherContact",
            key: "otherContact",
            // filters: otherContact,
            // onFilter: (value, record) => record.otherContact === value,
            filteredValue: filteredInfo.otherContact || null,
            render: (otherContact) => (otherContact ? otherContact : "-"),
            width: 100,
            ellipsis: {
              showTitle: false,
            },
            onCell: () => ({
              style: {
                maxWidth: 100,
              },
            }),
            render: (otherContact) => (
              <Tooltip placement="topLeft" title={otherContact}>
                {otherContact}
              </Tooltip>
            ),
          },
        ]
      : []),
    ...(hasBloodGroupInMemberLists
      ? [
          {
            title: "กรุ๊ปเลือด",
            dataIndex: "bloodGroup",
            key: "bloodGroup",
            // filters: bloodGroup,
            // onFilter: (value, record) => record.bloodGroup === value,
            filteredValue: filteredInfo.bloodGroup || null,
            render: (bloodGroup) => (bloodGroup ? bloodGroup : "-"),
            width: 100,
            ellipsis: {
              showTitle: false,
            },
            onCell: () => ({
              style: {
                maxWidth: 100,
              },
            }),
            render: (bloodGroup) => (
              <Tooltip placement="topLeft" title={bloodGroup}>
                {bloodGroup}
              </Tooltip>
            ),
          },
        ]
      : []),
    ...(hasOtherPhoneInMemberLists
      ? [
          {
            title: "เบอร์โทรศัพท์ญาติ",
            dataIndex: "otherPhone",
            key: "otherPhone",
            // filters: otherPhone,
            // onFilter: (value, record) => record.otherPhone === value,
            filteredValue: filteredInfo.otherPhone || null,
            render: (otherPhone) => (otherPhone ? otherPhone : "-"),
            width: 100,
            ellipsis: {
              showTitle: false,
            },
            onCell: () => ({
              style: {
                maxWidth: 100,
              },
            }),
            render: (otherPhone) => (
              <Tooltip placement="topLeft" title={otherPhone}>
                {otherPhone}
              </Tooltip>
            ),
          },
        ]
      : []),
    {
      title: "จัดส่งเสื้อ",
      dataIndex: "delivery",
      key: "delivery",
      render: (text) => (text ? "รับ" : "ไม่รับ"),
    },
    {
      title: "ประกันชีวิต",
      dataIndex: "lifeInsurance",
      key: "lifeInsurance",
      render: (text) => (text ? "รับ" : "ไม่รับ"),
    },

    {
      title: "สถานะการจ่ายเงิน",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let statusText;
        let badgeStatus;

        switch (status) {
          case "paid":
            statusText = "จ่ายเงินเรียบร้อย";
            badgeStatus = "success";
            break;
          case "pending":
            statusText = "รอการยืนยัน";
            badgeStatus = "warning";
            break;
          case "cancel":
            statusText = "ไม่สำเร็จ";
            badgeStatus = "error";
            break;
          default:
            statusText = status;
            badgeStatus = "default";
        }

        return <Badge status={badgeStatus} text={statusText} />;
      },
      filters: [
        { text: "จ่ายเงินเรียบร้อย", value: "paid" },
        { text: "รอการยืนยัน", value: "pending" },
        { text: "ไม่สำเร็จ", value: "cancel" },
      ],
      onFilter: (value, record) => record.status === value,
      filteredValue: filteredInfo.status || null,
      fixed: "right",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button type="primary" icon={<EditOutlined />} onClick={() => handleEdit(record)}></Button>
          <Button type="primary" danger icon={<DeleteOutlined />} onClick={() => showDeleteConfirm(record)}></Button>
        </Space>
      ),
      fixed: "right",
    },
  ];

  return (
    <AppLayout currentPage="หน้าแรก" getIconNav={<HomeOutlined style={{ fontSize: "24px" }} />} screens={screens}>
      {/* Menu Button */}
      <AdminLabel t={t} screens={screens} />
      <MenuButton t={t} screens={screens} navigate={navigate} />

      <Card
        title={`ชื่อกิจกรรม : ${events?.Title} (ตรวจสอบและบันทึกสถานะ)`}
        cover={
          <Space style={{ textAlign: "center" }}>
            <img
              src={events?.cover}
              style={{
                width: "100%",
                height: "auto",
                maxWidth: "600px",
                objectFit: "cover",
              }}
            />
          </Space>
        }
        style={{
          borderRadius: 0,
          borderTop: "15px solid ",
          borderColor: "#5E72EB",
          borderLeft: "none",
          borderRight: "none",
          borderBottom: "none",
        }}
      >
        <Flex justify="space-between" style={{ marginBottom: 16 }}>
          <DownloadExcel events={events} memberLists={memberLists} />
          <Button onClick={resetFilters}>ล้างการฟิลเตอร์</Button>
        </Flex>
        <Space style={{ marginBottom: 16 }}>
          <Select placeholder="เลือกสถานะการจ่ายเงิน" onChange={handleStatusChange} style={{ width: 200 }}>
            <Option value="paid">จ่ายเงินเรียบร้อย</Option>
            <Option value="pending">รอการยืนยัน</Option>
            <Option value="cancel">ไม่สำเร็จ</Option>
          </Select>
          <Button type="primary" onClick={applyStatusToSelected}>
            เปลี่ยนสถานะการจ่ายเงิน
          </Button>
        </Space>
        <Table
          loading={loading}
          dataSource={dataSource}
          columns={columns}
          pagination={pagination}
          onChange={handleTableChange}
          scroll={{ x: "max-content" }}
          bordered
          rowSelection={rowSelection}
          rowKey={(record, index) => index}
        />
      </Card>
      <Modal
        title="แก้ไขข้อมูล"
        open={isModalVisible}
        onCancel={handleModalCancel}
        onOk={handleModalSubmit}
        confirmLoading={loading}
      >
        <Form form={form} layout="vertical" name="edit_member">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="date" label="วันที่สมัคร">
                <span>{form.getFieldValue("date") || "ไม่มีข้อมูล"}</span>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="time" label="เวลาที่สมัคร">
                <span>{form.getFieldValue("time") || "ไม่มีข้อมูล"}</span>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item name="title" label="คำนำหน้า" rules={[{ required: true, message: "กรุณาใส่คำนำหน้า" }]}>
                <Select placeholder="กรุณาเลือกคำนำหน้า">
                  <Option value="นาย">นาย</Option>
                  <Option value="นาง">นาง</Option>
                  <Option value="นางสาว">นางสาว</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="firstName" label="ชื่อ" rules={[{ required: true, message: "กรุณาใส่ชื่อ" }]}>
                <Input placeholder="กรุณาใส่ชื่อ" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="lastName" label="สกุล" rules={[{ required: true, message: "กรุณาใส่นามสกุล" }]}>
                <Input placeholder="กรุณาใส่นามสกุล" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item name="raceOption" label="ชื่อรุ่น">
                <span>{form.getFieldValue("raceOption") || "ไม่มีข้อมูล"}</span>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="ageGroup" label="รุ่นอายุ" rules={[{ required: true, message: "กรุณาเลือกรุ่นอายุ" }]}>
                <Select placeholder="เลือกรุ่นอายุ">
                  {(() => {
                    const title = form.getFieldValue("title");

                    // ตรวจสอบค่า title เพื่อเลือก typeDetailMale หรือ typeDetailFemale
                    const ageOptions =
                      title === "นาย" ? dataEdit?.[0]?.typeDetailMale : dataEdit?.[0]?.typeDetailFemale;

                    // แสดง Option จาก typeDetailMale หรือ typeDetailFemale
                    return (
                      ageOptions &&
                      Object.entries(ageOptions).map(([key, age]) => (
                        <Option key={key} value={age}>
                          {age}
                        </Option>
                      ))
                    );
                  })()}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="shirtSize"
                label="ขนาดเสื้อ"
                rules={[{ required: true, message: "กรุณาเลือกขนาดเสื้อ" }]}
              >
                <Select placeholder="เลือกขนาดเสื้อ">
                  {(() => {
                    const title = form.getFieldValue("title");

                    // ตรวจสอบค่า title เพื่อเลือก typeDetailMale หรือ typeDetailFemale
                    const shirtSizeOptions =
                      title === "นาย" ? dataEdit?.[0]?.shirtSizeMale : dataEdit?.[0]?.shirtSizeFemale;

                    // แสดง Option จาก typeDetailMale หรือ typeDetailFemale
                    return (
                      shirtSizeOptions &&
                      Object.entries(shirtSizeOptions).map(([size, chest]) => (
                        <Option key={size} value={size}>
                          {size}
                        </Option>
                      ))
                    );
                  })()}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            {hasIdPassInMemberLists ? (
              <Col span={11}>
                <Form.Item
                  name="idPass"
                  label="เลขบัตรประชาชนหรือเลขพาสปอร์ต"
                  rules={[{ required: true, message: "กรุณาใส่เลขบัตรประชาชนหรือเลขพาสปอร์ต" }]}
                >
                  <Input placeholder="กรุณาใส่เลขบัตรประชาชนหรือเลขพาสปอร์ต" />
                </Form.Item>
              </Col>
            ) : (
              <></>
            )}
            {hasOtherContactInMemberLists ? (
              <Col span={13}>
                <Form.Item
                  name="otherContact"
                  label="ช่องทางติดต่อออนไลน์ (Facebook/Line)"
                  rules={[{ required: true, message: "กรุณาใส่ช่องทางติดต่อออนไลน์" }]}
                >
                  <Input placeholder="กรุณาใส่ช่องทางติดต่อออนไลน์" />
                </Form.Item>
              </Col>
            ) : (
              <></>
            )}
          </Row>
          <Row gutter={16}>
            {hasBloodGroupInMemberLists ? (
              <Col span={10}>
                <Form.Item
                  name="bloodGroup"
                  label="กรุ๊ปเลือด"
                  rules={[{ required: true, message: "กรุณาใส่กรุ๊ปเลือด" }]}
                >
                  <Select placeholder="กรุณาเลือกคำนำหน้า">
                    <Option value="A">A</Option>
                    <Option value="B">B</Option>
                    <Option value="AB">AB</Option>
                    <Option value="O">O</Option>
                  </Select>
                </Form.Item>
              </Col>
            ) : (
              <></>
            )}
            {hasOtherPhoneInMemberLists ? (
              <Col span={14}>
                <Form.Item
                  name="otherPhone"
                  label="เบอร์โทรศัพท์ญาติ"
                  rules={[{ required: true, message: "กรุณาใส่เบอร์โทรศัพท์ญาติ	" }]}
                >
                  <Input placeholder="กรุณาใส่เบอร์โทรศัพท์ญาติ	" />
                </Form.Item>
              </Col>
            ) : (
              <></>
            )}
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item name="totalPrice" label="ราคา">
                <span>{form.getFieldValue("totalPrice") || "ไม่มีข้อมูล"}</span>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="birthDate" label="วันเกิด" rules={[{ required: true, message: "กรุณาใส่วันเกิด" }]}>
                <Input placeholder="กรุณาใส่วันเกิด" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="phone" label="เบอร์โทรศัพท์">
                <span>{form.getFieldValue("phone") || "ไม่มีข้อมูล"}</span>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="address" label="ที่อยู่" rules={[{ required: true, message: "กรุณาใส่ที่อยู่" }]}>
            <Input placeholder="กรุณาใส่ที่อยู่" />
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="refAcc" label="ผู้สมัครให้">
                <span>{form.getFieldValue("refAcc") || "ไม่มีข้อมูล"}</span>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="groupName" label="ชื่อทีม">
                <Input placeholder="ชื่อทีม" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="delivery" hidden>
            <Input style={{ display: "none" }} />
          </Form.Item>
          <Form.Item name="lifeInsurance" hidden>
            <Input style={{ display: "none" }} />
          </Form.Item>
          <Form.Item name="qrImage" hidden>
            <Input style={{ display: "none" }} />
          </Form.Item>
          <Form.Item name="uid" hidden>
            <Input style={{ display: "none" }} />
          </Form.Item>
          <Form.Item
            name="status"
            label="สถานะการจ่ายเงิน"
            rules={[{ required: true, message: "กรุณาเลือกสถานะการจ่ายเงิน" }]}
          >
            <Select placeholder="เลือกสถานะการจ่ายเงิน">
              <Option value="paid">จ่ายเงินเรียบร้อย</Option>
              <Option value="pending">รอการยืนยัน</Option>
              <Option value="cancel">ไม่สำเร็จ</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </AppLayout>
  );
};
export default ManageCheckAndUpdateRunPage;
