import React from "react";
import { Row, Col, Card, Button, Typography, Avatar } from "antd";
import {
  CalendarOutlined,
  EnvironmentOutlined,
  UserOutlined,
  HomeOutlined,
  PhoneOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Loading } from "../loading-spin";
import { useAppContext } from "../../context/app-provider";
import { useTranslation } from "react-i18next";
import HotelCard from "../admin/hotel-card";
import CarRentCard from "../admin/car-rent-card";
import TourCard from "../admin/tour-card";
import TripCard from "../admin/trip-card";
import EvChargerCard from "../admin/ev-charger-card";
import ShopCard from "../admin/shop-card";
import ShuttleCard from "../admin/shuttle-card";
import TravelCard from "../admin/travel-card";
import CalendarEventCard from "../admin/calendar-event-card";
import PhotographerCard from "../admin/photographer-card";
import SpiritualCard from "../admin/spiritual-card";
import FoodCard from "../admin/food-card";

const { Paragraph } = Typography;
const { Text } = Typography;

const RunningEventList = ({
  activeButton,
  eventOpen,
  eventClose,
  publicRelationsData,
  user,
  userAdmin,
  setSearchTerm,
  setSelectedProvince,
  setSelectedMonth,
  loading,
}) => {
  const navigate = useNavigate();
  const { language } = useAppContext();
  const { t } = useTranslation();

  const handleCardClick = (linkContact) => {
    if (linkContact) {
      window.open(linkContact, "_blank"); // เปิดลิงก์ในแท็บใหม่
    } else {
      console.warn("No linkContact provided for this card.");
    }
  };

  const handleNavigation = (eventId, path) => {
    setSearchTerm("");
    setSelectedProvince("");
    setSelectedMonth("");
    navigate(path);
  };

  const renderButtonsOpenEvent = (event) => {
    const memberCount = event?.member ? Object.keys(event.member).length : 0;

    return (
      <>
        <Col xs={12} sm={5} md={4} lg={7} xl={5} xxl={4}>
          <Button
            size="large"
            block
            type="text"
            style={{ backgroundColor: "#EFE26A" }}
            onClick={() =>
              handleNavigation(
                event.id,
                userAdmin
                  ? `/${language}/event/${activeButton}/detail/${event.id}`
                  : `/${language}/sport-register/${activeButton}/detail/${event.id}`
              )
            }
          >
            <Paragraph strong style={{ margin: 0 }}>
              {t("sport-register-page.button-card-event-1")}
            </Paragraph>
          </Button>
        </Col>
        <Col xs={12} sm={5} md={4} lg={7} xl={5} xxl={4}>
          <Button size="large" block type="text" style={{ backgroundColor: "#2DB2AD" }}>
            <Paragraph strong style={{ margin: 0, color: "#FFFFFF" }}>
              {t("sport-register-page.button-card-event-2")} {memberCount} <UserOutlined />
            </Paragraph>
          </Button>
        </Col>
        <Col xs={12} sm={7} md={5} lg={10} xl={7} xxl={5}>
          <Button
            size="large"
            block
            type="text"
            style={{ backgroundColor: "#5E72EB" }}
            onClick={() =>
              handleNavigation(
                event.id,
                userAdmin
                  ? `/${language}/event/${activeButton}/manage-check-update/member/${event.id}`
                  : `/${language}/sport-register/${activeButton}/member/${event.id}`
              )
            }
          >
            <Paragraph strong style={{ margin: 0, color: "#FFFFFF" }}>
              {userAdmin ? t("sport-register-page.button-card-event-5") : t("sport-register-page.button-card-event-3")}
            </Paragraph>
          </Button>
        </Col>
        {!userAdmin ? (
          <Col xs={24} sm={24} md={6} lg={14} xl={24} xxl={6}>
            <Button
              size="large"
              block
              type="text"
              style={{ backgroundColor: "#130C63" }}
              onClick={() =>
                handleNavigation(event.id, `/${language}/sport-register/${activeButton}/register/${event.id}`)
              }
            >
              <Paragraph strong style={{ margin: 0, color: "#FFFFFF" }}>
                {t("sport-register-page.button-card-event-4")}
              </Paragraph>
            </Button>
          </Col>
        ) : (
          <Col xs={24} sm={24} md={6} lg={14} xl={24} xxl={6}>
            <Button
              size="large"
              block
              type="text"
              style={{ backgroundColor: "#130C63" }}
              onClick={() =>
                handleNavigation(
                  event.id,
                  `/${language}/event/${activeButton}/manage-check-update/order-shirt/${event.id}`
                )
              }
            >
              <Paragraph strong style={{ margin: 0, color: "#FFFFFF" }}>
                {t("sport-register-page.button-card-event-6")}
              </Paragraph>
            </Button>
          </Col>
        )}
      </>
    );
  };
  const renderButtonsCloseEvent = (event) => {
    const memberCount = event?.member ? Object.keys(event.member).length : 0;

    return (
      <>
        <Col xs={12} sm={5} md={4} lg={7} xl={5} xxl={4}>
          <Button
            size="large"
            block
            type="text"
            style={{ backgroundColor: "#EFE26A" }}
            onClick={() =>
              handleNavigation(
                event.id,
                userAdmin
                  ? `/${language}/event/${activeButton}/detail/${event.id}`
                  : `/${language}/sport-register/${activeButton}/detail/${event.id}`
              )
            }
          >
            <Paragraph strong style={{ margin: 0 }}>
              {t("sport-register-page.button-card-event-1")}
            </Paragraph>
          </Button>
        </Col>
        <Col xs={12} sm={5} md={4} lg={7} xl={5} xxl={4}>
          <Button size="large" block type="text" style={{ backgroundColor: "#2DB2AD" }}>
            <Paragraph strong style={{ margin: 0, color: "#FFFFFF" }}>
              {t("sport-register-page.button-card-event-2")} {memberCount} <UserOutlined />
            </Paragraph>
          </Button>
        </Col>
        <Col xs={12} sm={7} md={5} lg={10} xl={7} xxl={5}>
          <Button
            size="large"
            block
            type="text"
            style={{ backgroundColor: "#5E72EB" }}
            onClick={() =>
              handleNavigation(
                event.id,
                userAdmin
                  ? `/${language}/event/${activeButton}/manage-check-update/member/${event.id}`
                  : `/${language}/sport-register/${activeButton}/member/${event.id}`
              )
            }
          >
            <Paragraph strong style={{ margin: 0, color: "#FFFFFF" }}>
              {userAdmin ? t("sport-register-page.button-card-event-5") : t("sport-register-page.button-card-event-3")}
            </Paragraph>
          </Button>
        </Col>
        {!userAdmin ? (
          <Col xs={24} sm={24} md={6} lg={14} xl={24} xxl={6}>
            <Button
              size="large"
              block
              type="text"
              disabled
              style={{ backgroundColor: "#130C63" }}
              onClick={() =>
                handleNavigation(event.id, `/${language}/sport-register/${activeButton}/register/${event.id}`)
              }
            >
              <Paragraph strong style={{ margin: 0, color: "#FFFFFF" }}>
                {t("sport-register-page.button-card-event-4-1")}
              </Paragraph>
            </Button>
          </Col>
        ) : (
          <Col xs={24} sm={24} md={6} lg={14} xl={24} xxl={6}>
            <Button
              size="large"
              block
              type="text"
              style={{ backgroundColor: "#130C63" }}
              onClick={() =>
                handleNavigation(
                  event.id,
                  `/${language}/event/${activeButton}/manage-check-update/order-shirt/${event.id}`
                )
              }
            >
              <Paragraph strong style={{ margin: 0, color: "#FFFFFF" }}>
                {t("sport-register-page.button-card-event-6")}
              </Paragraph>
            </Button>
          </Col>
        )}
      </>
    );
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Row justify="center" align="middle" gutter={[32, 32]} style={{ marginTop: "20px" }}>
      {["run", "bicycle", "sport"].includes(activeButton) ? (
        <>
          {eventOpen?.map((event) => (
            <Col xs={23} sm={23} md={23} lg={11} xl={11} xxl={12} key={event.id}>
              <Card
                cover={
                  <div style={{ aspectRatio: "16/9", overflow: "hidden" }}>
                    <img src={event.cover} alt={event.title} style={{ width: "100%", height: "100%" }} />
                  </div>
                }
              >
                <Card.Meta
                  title={event?.Title}
                  description={`${t("sport-register-page.end-event")} ${event?.end}`}
                  style={{ paddingBottom: "10px" }}
                />
                <Row gutter={[8, 16]}>
                  <Col xs={12} sm={7} md={5} lg={10} xl={7} xxl={5}>
                    <Typography.Paragraph level={5} style={{ margin: 0 }}>
                      <EnvironmentOutlined style={{ color: "red" }} /> {event.location}
                    </Typography.Paragraph>
                    <Typography.Paragraph level={5} style={{ margin: 0 }}>
                      <CalendarOutlined /> {event.date}
                    </Typography.Paragraph>
                  </Col>
                  {renderButtonsOpenEvent(event)}
                </Row>
              </Card>
            </Col>
          ))}
          {eventClose?.map((event) => (
            <Col xs={23} sm={23} md={23} lg={11} xl={11} xxl={12} key={event.id}>
              <Card
                cover={
                  <div style={{ aspectRatio: "16/9", overflow: "hidden" }}>
                    <img src={event.cover} alt={event.title} style={{ width: "100%", height: "100%" }} />
                  </div>
                }
              >
                <Card.Meta
                  title={event?.Title}
                  description={`${t("sport-register-page.end-event")} ${event?.end}`}
                  style={{ paddingBottom: "10px" }}
                />
                <Row gutter={[8, 16]}>
                  <Col xs={12} sm={7} md={5} lg={10} xl={7} xxl={5}>
                    <Typography.Paragraph level={5} style={{ margin: 0 }}>
                      <EnvironmentOutlined style={{ color: "red" }} /> {event.location}
                    </Typography.Paragraph>
                    <Typography.Paragraph level={5} style={{ margin: 0 }}>
                      <CalendarOutlined /> {event.date}
                    </Typography.Paragraph>
                  </Col>
                  {renderButtonsCloseEvent(event)}
                </Row>
              </Card>
            </Col>
          ))}
        </>
      ) : activeButton === "hotel" ? (
        publicRelationsData.map((item) => (
          <Col key={item.id} xs={23} sm={23} md={23} lg={8} xl={8} xxl={8}>
            <HotelCard
              loading={loading}
              id={item?.id}
              name={item?.name}
              companyName={item?.companyName}
              location={`${item?.province}`}
              phone={item?.phone}
              backgroundImage={item?.imageCover}
              logo={item?.imageLogo}
              show={item?.show}
              onClick={() => handleCardClick(item?.linkContact)}
              userAdmin={userAdmin}
              navigate={navigate}
              language={language}
            />
          </Col>
        ))
      ) : activeButton === "carRent" ? (
        publicRelationsData.map((item) => (
          <Col key={item.id} xs={23} sm={23} md={23} lg={8} xl={8} xxl={8}>
            <CarRentCard
              loading={loading}
              id={item?.id}
              name={item?.name}
              location={`${item?.province}`}
              phone={item?.phone}
              backgroundImage={item?.imageCover}
              logo={item?.imageLogo}
              show={item?.show}
              onClick={() => handleCardClick(item?.linkContact)}
              userAdmin={userAdmin}
              navigate={navigate}
              language={language}
            />
          </Col>
        ))
      ) : activeButton === "shuttle" ? (
        publicRelationsData.map((item) => (
          <Col key={item.id} xs={23} sm={23} md={23} lg={6} xl={6} xxl={6}>
            <ShuttleCard
              loading={loading}
              id={item?.id}
              name={item?.name}
              companyName={item?.companyName}
              location={`${item?.province}`}
              phone={item?.phone}
              backgroundImage={item?.imageCover}
              logo={item?.imageLogo}
              show={item?.show}
              onClick={() => handleCardClick(item?.linkContact)}
              userAdmin={userAdmin}
              navigate={navigate}
              language={language}
            />
          </Col>
        ))
      ) : activeButton === "tour" ? (
        publicRelationsData.map((item) => (
          <Col key={item.id} xs={23} sm={23} md={23} lg={11} xl={11} xxl={12}>
            <TourCard
              loading={loading}
              id={item?.id}
              name={item?.name}
              companyName={item?.companyName}
              location={`${item?.province}`}
              phone={item?.phone}
              startAndEndEvent={item?.startAndEndEvent}
              date={item?.date}
              price={item?.price}
              backgroundImage={item?.imageCover}
              logo={item?.imageLogo}
              show={item?.show}
              onClick={() => handleCardClick(item?.linkContact)}
              userAdmin={userAdmin}
              navigate={navigate}
              language={language}
            />
          </Col>
        ))
      ) : activeButton === "oneDayTrip" ? (
        publicRelationsData.map((item) => (
          <Col key={item.id} xs={23} sm={23} md={23} lg={6} xl={6} xxl={6}>
            <TripCard
              loading={loading}
              id={item?.id}
              name={item?.name}
              companyName={item?.companyName}
              location={`${item?.province}`}
              backgroundImage={item?.imageCover}
              show={item?.show}
              onClick={() => handleCardClick(item?.linkContact)}
              userAdmin={userAdmin}
              navigate={navigate}
              language={language}
            />
          </Col>
        ))
      ) : activeButton === "evCharger" ? (
        publicRelationsData.map((item) => (
          <Col key={item.id} xs={23} sm={23} md={23} lg={6} xl={6} xxl={6}>
            <EvChargerCard
              loading={loading}
              id={item?.id}
              name={item?.name}
              location={`${item?.province}`}
              backgroundImage={item?.imageCover}
              show={item?.show}
              onClick={() => handleCardClick(item?.linkContact)}
              userAdmin={userAdmin}
              navigate={navigate}
              language={language}
            />
          </Col>
        ))
      ) : activeButton === "shop" ? (
        publicRelationsData.map((item) => (
          <Col key={item.id} xs={23} sm={23} md={23} lg={8} xl={8} xxl={8}>
            <ShopCard
              loading={loading}
              activeButton={activeButton}
              id={item?.id}
              companyName={item?.companyName}
              name={item?.name}
              phone={item?.phone}
              location={`${item?.province}`}
              backgroundImage={item?.imageCover}
              logo={item?.imageLogo}
              show={item?.show}
              onClick={() => handleCardClick(item?.linkContact)}
              userAdmin={userAdmin}
              navigate={navigate}
              language={language}
            />
          </Col>
        ))
      ) : activeButton === "eventOrganizer" ? (
        publicRelationsData.map((item) => (
          <Col key={item.id} xs={23} sm={23} md={23} lg={8} xl={8} xxl={8}>
            <ShopCard
              loading={loading}
              activeButton={activeButton}
              id={item?.id}
              companyName={item?.companyName}
              name={item?.name}
              phone={item?.phone}
              location={`${item?.province}`}
              backgroundImage={item?.imageCover}
              logo={item?.imageLogo}
              show={item?.show}
              onClick={() => handleCardClick(item?.linkContact)}
              userAdmin={userAdmin}
              navigate={navigate}
              language={language}
            />
          </Col>
        ))
      ) : activeButton === "shirtShop" ? (
        publicRelationsData.map((item) => (
          <Col key={item.id} xs={23} sm={23} md={23} lg={8} xl={8} xxl={8}>
            <ShopCard
              loading={loading}
              activeButton={activeButton}
              id={item?.id}
              companyName={item?.companyName}
              name={item?.name}
              phone={item?.phone}
              location={`${item?.province}`}
              backgroundImage={item?.imageCover}
              logo={item?.imageLogo}
              show={item?.show}
              onClick={() => handleCardClick(item?.linkContact)}
              userAdmin={userAdmin}
              navigate={navigate}
              language={language}
            />
          </Col>
        ))
      ) : activeButton === "trophiesMedals" ? (
        publicRelationsData.map((item) => (
          <Col key={item.id} xs={23} sm={23} md={23} lg={8} xl={8} xxl={8}>
            <ShopCard
              loading={loading}
              activeButton={activeButton}
              id={item?.id}
              companyName={item?.companyName}
              name={item?.name}
              phone={item?.phone}
              location={`${item?.province}`}
              backgroundImage={item?.imageCover}
              logo={item?.imageLogo}
              show={item?.show}
              onClick={() => handleCardClick(item?.linkContact)}
              userAdmin={userAdmin}
              navigate={navigate}
              language={language}
            />
          </Col>
        ))
      ) : activeButton === "bibChip" ? (
        publicRelationsData.map((item) => (
          <Col key={item.id} xs={23} sm={23} md={23} lg={8} xl={8} xxl={8}>
            <ShopCard
              loading={loading}
              activeButton={activeButton}
              id={item?.id}
              companyName={item?.companyName}
              name={item?.name}
              phone={item?.phone}
              location={`${item?.province}`}
              backgroundImage={item?.imageCover}
              logo={item?.imageLogo}
              show={item?.show}
              onClick={() => handleCardClick(item?.linkContact)}
              userAdmin={userAdmin}
              navigate={navigate}
              language={language}
            />
          </Col>
        ))
      ) : activeButton === "travel" ? (
        publicRelationsData.map((item) => (
          <Col key={item.id} xs={23} sm={23} md={23} lg={8} xl={8} xxl={8}>
            <TravelCard
              loading={loading}
              activeButton={activeButton}
              id={item?.id}
              companyName={item?.companyName}
              name={item?.name}
              phone={item?.phone}
              location={`${item?.province}`}
              backgroundImage={item?.imageCover}
              show={item?.show}
              onClick={() => handleCardClick(item?.linkContact)}
              userAdmin={userAdmin}
              navigate={navigate}
              language={language}
            />
          </Col>
        ))
      ) : activeButton === "calendarEvent" ? (
        publicRelationsData.map((item) => (
          <Col key={item.id} xs={23} sm={23} md={23} lg={8} xl={8} xxl={8}>
            <CalendarEventCard
              loading={loading}
              activeButton={activeButton}
              date={item?.date}
              id={item?.id}
              name={item?.name}
              location={`${item?.province}`}
              backgroundImage={item?.imageCover}
              show={item?.show}
              onClick={() => handleCardClick(item?.linkContact)}
              userAdmin={userAdmin}
              navigate={navigate}
              language={language}
            />
          </Col>
        ))
      ) : activeButton === "photographer" ? (
        publicRelationsData.map((item) => (
          <Col key={item.id} xs={23} sm={23} md={23} lg={8} xl={8} xxl={8}>
            <PhotographerCard
              loading={loading}
              activeButton={activeButton}
              id={item?.id}
              phone={item?.phone}
              companyName={item?.companyName}
              location={`${item?.province}`}
              backgroundImage={item?.imageCover}
              logo={item?.imageLogo}
              show={item?.show}
              onClick={() => handleCardClick(item?.linkContact)}
              userAdmin={userAdmin}
              navigate={navigate}
              language={language}
            />
          </Col>
        ))
      ) : activeButton === "spiritual" ? (
        publicRelationsData.map((item) => (
          <Col key={item.id} xs={23} sm={23} md={23} lg={8} xl={8} xxl={8}>
            <SpiritualCard
              loading={loading}
              activeButton={activeButton}
              id={item?.id}
              companyName={item?.companyName}
              name={item?.name}
              phone={item?.phone}
              location={`${item?.province}`}
              backgroundImage={item?.imageCover}
              show={item?.show}
              onClick={() => handleCardClick(item?.linkContact)}
              userAdmin={userAdmin}
              navigate={navigate}
              language={language}
            />
          </Col>
        ))
      ) : activeButton === "food" ? (
        publicRelationsData.map((item) => (
          <Col key={item.id} xs={23} sm={23} md={23} lg={8} xl={8} xxl={8}>
            <FoodCard
              loading={loading}
              activeButton={activeButton}
              id={item?.id}
              companyName={item?.companyName}
              name={item?.name}
              phone={item?.phone}
              location={`${item?.province}`}
              backgroundImage={item?.imageCover}
              logo={item?.imageLogo}
              show={item?.show}
              onClick={() => handleCardClick(item?.linkContact)}
              userAdmin={userAdmin}
              navigate={navigate}
              language={language}
            />
          </Col>
        ))
      ) : (
        <></>
      )}
    </Row>
  );
};

export default RunningEventList;
