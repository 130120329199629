import { Form, Input } from "antd";

const LinkContact = ({ label, name, placeholder }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: true, message: "กรุณากรอกชื่อ" }]}>
      <Input placeholder={placeholder} />
    </Form.Item>
  );
};

export default LinkContact;
