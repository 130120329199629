import axios from "axios";
import { notification } from "antd";

export const CreatePublicRelations = async (type, data) => {
  try {
    const response = await axios.post("https://push-new-events-data-hfrl7t3qfq-uc.a.run.app", {
      Type: type,
      Data: data,
    });

    return response.data;
  } catch (error) {
    console.error("Error while posting data to API:", error);
  }
};

export const UpdatePublicRelations = async (id, type, data) => {
  try {
    const response = await axios.post("https://edit-events-data-hfrl7t3qfq-uc.a.run.app", {
      ID: id,
      Type: type,
      Data: data,
    });

    return response.data;
  } catch (error) {
    console.error("Error while posting data to API:", error);
  }
};

export const ShowPublicRelations = async (id, type, show) => {
  try {
    const response = await axios.post("https://show-events-data-hfrl7t3qfq-uc.a.run.app", {
      ID: id,
      Type: type,
      show: show,
    });

    return response.data;
  } catch (error) {
    console.error("Error while posting data to API:", error);
  }
};

export const GetPublicRelations = async (eventType, lang) => {
  try {
    const response = await axios.get("https://get-new-events-admin-hfrl7t3qfq-uc.a.run.app", {
      params: {
        eventType: eventType,
        lang: lang,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error while posting data to API:", error);
  }
};

export const GetPublicRelationsUser = async (eventType, lang) => {
  try {
    const response = await axios.get("https://get-new-events-hfrl7t3qfq-uc.a.run.app", {
      params: {
        eventType: eventType,
        lang: lang,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error while posting data to API:", error);
  }
};

export const uploadImages = async (type, data) => {
  try {
    const response = await axios.post("https://upload-images-events-hfrl7t3qfq-uc.a.run.app", {
      Type: type,
      data: data,
    });
    return response.data;
  } catch (error) {
    console.error("Error while uploading images to API:", error);
  }
};

export const EditUploadImages = async (type, data) => {
  try {
    const response = await axios.post("https://edit-images-events-hfrl7t3qfq-uc.a.run.app", {
      Type: type,
      data: data,
    });
    return response.data;
  } catch (error) {
    console.error("Error while uploading images to API:", error);
  }
};
