import React from "react";
import { Card, Grid, Row, Typography } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import AppLayoutAdmin from "../../../../layout/app-layout-admin";
import AdminLabel from "../../../../components/admin/admin-label";
import MenuButton from "../../../../components/menu-button";
import CategoryButton from "../../../../components/sport-register/category-button";
import FormControl from "../../../../components/sport-register/form-control";
import { fetchEventById } from "../../../../services/fetch-events";
import { useAppContext } from "../../../../context/app-provider";
import { useTranslation } from "react-i18next";
import AppLayout from "../../../../layout/app-layout";

const { useBreakpoint } = Grid;

const AdminEventDetailBikePage = ({}) => {
  const { id } = useParams();
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = React.useState("bicycle");
  const [loading, setLoading] = React.useState(true);
  const [events, setEvents] = React.useState([]);
  const { language } = useAppContext();
  const { t } = useTranslation();

  React.useEffect(() => {
    const getEventData = async () => {
      try {
        setLoading(true);
        const { eventData } = await fetchEventById(id, language);
        setEvents(eventData);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getEventData();
  }, [id, language]);

  const handleButtonClick = (type) => {
    setActiveButton(type);

    if (type === "run") {
      navigate(`/${language}/event/run`);
    } else if (type === "bicycle") {
      navigate(`/${language}/event/bicycle`);
    } else if (type === "sport") {
      navigate(`/${language}/event/sport`);
    }
  };

  return (
    <AppLayout currentPage="หน้าแรก" getIconNav={<HomeOutlined style={{ fontSize: "24px" }} />} screens={screens}>
      <AdminLabel t={t} screens={screens} />
      <MenuButton t={t} screens={screens} navigate={navigate} />
      <div style={{ backgroundColor: "#5E72EB", padding: "24px" }}>
        <Row gutter={[16, 16]} align="middle">
          <CategoryButton t={t} activeButton={activeButton} handleButtonClick={handleButtonClick} />
          <FormControl t={t} screens={screens} />
        </Row>
      </div>
      <Card
        loading={loading}
        style={{
          borderRadius: 0,
          borderLeft: "none",
          borderRight: "none",
          borderBottom: "none",
          padding: "20px",
        }}
      >
        <iframe
          src={events?.detail}
          style={{
            width: "100%",
            maxWidth: "400vw",
            height: "auto",
            border: "none",
            minHeight: "200vh",
          }}
          title="Canva Design"
        />
      </Card>
    </AppLayout>
  );
};

export default AdminEventDetailBikePage;
