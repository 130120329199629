import React from "react";
import { Form, Button, message, Grid, Row, Col, Checkbox, Space } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import NameInput from "../../../components/form-public-relations/name-input";
import ImageUpload from "../../../components/form-public-relations/image-input";
import { ProvinceSelectEN, ProvinceSelectTH } from "../../../components/form-public-relations/province-select";
import TagInput from "../../../components/form-public-relations/tag-input";
import { useTranslation } from "react-i18next";
import AppLayout from "../../../layout/app-layout";
import AdminLabel from "../../../components/admin/admin-label";
import HotelCard from "../../../components/admin/hotel-card";
import PhoneInput from "../../../components/form-public-relations/phone-input";
import LinkContact from "../../../components/form-public-relations/link-contact";
import { UpdatePublicRelations, EditUploadImages } from "../../../services/fetch-public-relations";
import { useNavigate, useParams } from "react-router-dom";
import { fetchEventById } from "../../../services/fetch-events";
import { useAppContext } from "../../../context/app-provider";
import { Loading, OverlayLoading } from "../../../components/loading-spin";

const EditHotelPage = () => {
  const navigate = useNavigate();
  const [formTH] = Form.useForm();
  const [formEN] = Form.useForm();
  const [formDataTH, setFormDataTH] = React.useState({});
  const [formDataEN, setFormDataEN] = React.useState({});
  const [imagePreviewTH, setImagePreviewTH] = React.useState({});
  const [imagePreviewEN, setImagePreviewEN] = React.useState({});
  const [imageCoverTH, setImageCoverTH] = React.useState({});
  const [logoTH, setLogoTH] = React.useState({});
  const [imageCoverEN, setImageCoverEN] = React.useState({});
  const [logoEN, setLogoEN] = React.useState({});
  const { language } = useAppContext();
  const [loading, setLoading] = React.useState(false);
  const [loadingPage, setLoadingPage] = React.useState(false);
  const { t } = useTranslation();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const { id } = useParams();
  const fileNameCoverTH = `publicRelationsImage/hotel/cover_th_${id}`;
  const fileNameLogoTH = `publicRelationsImage/hotel/logo_th_${id}`;
  const fileNameCoverEN = `publicRelationsImage/hotel/cover_en_${id}`;
  const fileNameLogoEN = `publicRelationsImage/hotel/logo_en_${id}`;

  React.useEffect(() => {
    setLoadingPage(true);
    const getEventDataThai = async () => {
      const { eventData } = await fetchEventById(id, "th");
      setFormDataTH(eventData);
      setImageCoverTH(eventData.imageCover);
      setLogoTH(eventData.imageLogo);
      setLoadingPage(false);
    };
    const getEventDataEng = async () => {
      const { eventData } = await fetchEventById(id, "en");
      setFormDataEN(eventData);
      setImageCoverEN(eventData.imageCover);
      setLogoEN(eventData.imageLogo);
      setLoadingPage(false);
    };

    getEventDataThai();
    getEventDataEng();
  }, [id, language]);

  React.useEffect(() => {
    if (formDataEN) {
      formEN?.setFieldsValue(formDataEN);
    }
    if (formDataTH) {
      formTH?.setFieldsValue(formDataTH);
    }
  }, [formDataEN, formDataTH]);

  const handleCardTHClick = () => {
    window.open(formDataTH?.linkContact, "_blank");
  };

  const handleCardENClick = () => {
    window.open(formDataEN?.linkContact, "_blank");
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const displayCardTH = formDataTH?.show;
      const displayCardEN = formDataEN?.show;
      const [enValues, thValues] = await Promise.all([formEN?.validateFields(), formTH?.validateFields()]);

      const hasFileChanged = (newFile, oldFile) => {
        if (!newFile && !oldFile) return false;
        if (!newFile || !oldFile) return true;

        if (newFile instanceof File && oldFile instanceof File) {
          return (
            newFile.name !== oldFile.name ||
            newFile.size !== oldFile.size ||
            newFile.lastModified !== oldFile.lastModified
          );
        } else if (typeof newFile === "string" && typeof oldFile === "string") {
          return newFile !== oldFile;
        }
        return false;
      };

      // Collect files to upload
      const filesToUpload = [
        {
          condition: hasFileChanged(thValues?.imageCover, imageCoverTH),
          file: thValues?.imageCover,
          fileName: fileNameCoverTH,
        },
        {
          condition: hasFileChanged(thValues?.imageLogo, logoTH),
          file: thValues?.imageLogo,
          fileName: fileNameLogoTH,
        },
        {
          condition: hasFileChanged(enValues?.imageCover, imageCoverEN),
          file: enValues?.imageCover,
          fileName: fileNameCoverEN,
        },
        {
          condition: hasFileChanged(enValues?.imageLogo, logoEN),
          file: enValues?.imageLogo,
          fileName: fileNameLogoEN,
        },
      ].filter(({ condition }) => condition);

      // Upload files if needed
      if (filesToUpload.length > 0) {
        const uploadResponse = await EditUploadImages(
          "hotel",
          filesToUpload.map(({ file, fileName }) => ({ file, fileName }))
        );

        if (!uploadResponse) throw new Error("เกิดข้อผิดพลาดในการอัปโหลดรูปภาพ");

        // Process and map uploaded URLs
        const updatedResponseImageUrl = Object.fromEntries(
          Object.entries(uploadResponse).map(([key, value]) => [
            key.replace(`/_h${id}\\d+$/`, ""), // Remove suffix
            value,
          ])
        );

        const dataToSend = {
          th: {
            ...thValues,
            imageCover: updatedResponseImageUrl[fileNameCoverTH] || imageCoverTH,
            imageLogo: updatedResponseImageUrl[fileNameLogoTH] || logoTH,
            show: displayCardTH,
          },
          en: {
            ...enValues,
            imageCover: updatedResponseImageUrl[fileNameCoverEN] || imageCoverEN,
            imageLogo: updatedResponseImageUrl[fileNameLogoEN] || logoEN,
            show: displayCardEN,
          },
        };

        const response = await UpdatePublicRelations(id, "hotel", dataToSend);

        if (response) {
          message.success("บันทึกข้อมูลสำเร็จ!");
          navigate(`/${language}/admin/hotel`);
        } else {
          throw new Error("เกิดข้อผิดพลาดในการบันทึกข้อมูล");
        }
      } else {
        // If no file changes, send data without image changes
        const dataToSendNoImage = {
          th: { ...thValues, show: displayCardTH },
          en: { ...enValues, show: displayCardEN },
        };

        const response = await UpdatePublicRelations(id, "hotel", dataToSendNoImage);

        if (response) {
          message.success("บันทึกข้อมูลสำเร็จ!");
          navigate(`/${language}/admin/hotel`);
        } else {
          throw new Error("เกิดข้อผิดพลาดในการบันทึกข้อมูล");
        }
      }
    } catch (error) {
      console.error("Validation or API error:", error);
      message.error(error.message || "กรุณาตรวจสอบข้อมูลให้ครบถ้วน");
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    message.error("กรุณากรอกข้อมูลให้ครบถ้วน");
  };

  const handleImageChangeTH = ({ name, imageURL, rawData }) => {
    setImagePreviewTH((prevData) => ({
      ...prevData,
      [name]: imageURL,
    }));

    formTH?.setFieldsValue({
      [name]: rawData,
    });
  };

  const handleImageChangeEN = ({ name, imageURL, rawData }) => {
    setImagePreviewEN((prevData) => ({
      ...prevData,
      [name]: imageURL,
    }));

    formEN?.setFieldsValue({
      [name]: rawData,
    });
  };

  const renderFormTH = () => (
    <Form
      form={formTH}
      layout="vertical"
      onFinish={handleSubmit}
      onFinishFailed={onFinishFailed}
      onValuesChange={(changedValues, allValues) => {
        setFormDataTH(allValues);
      }}
    >
      <Row>
        <Col xs={23} sm={23} md={23} lg={11} xl={11} xxl={24}>
          <h3>ตัวอย่างการแสดงผล</h3>
          <HotelCard
            name={formDataTH?.name}
            companyName={formDataTH?.companyName}
            location={formDataTH?.province}
            phone={formDataTH?.phone}
            backgroundImage={imagePreviewTH?.imageCover || formDataTH?.imageCover}
            logo={imagePreviewTH?.imageLogo || formDataTH?.imageLogo}
            onClick={handleCardTHClick}
          />
        </Col>
      </Row>
      <NameInput
        label="ชื่อเพจโรงแรม"
        name="name"
        requiredLabel="กรุณากรอกชื่อเพจโรงแรม"
        placeholder="กรอกชื่อเพจโรงแรม"
      />
      <NameInput
        label="ชื่อโรงแรม"
        name="companyName"
        requiredLabel="กรุณากรอกชื่อโรงแรม"
        placeholder="กรอกชื่อโรงแรม"
      />

      <ImageUpload
        name="imageCover"
        label="รูปภาพพื้นหลัง"
        t={t}
        folderName="hotel"
        onFileChange={handleImageChangeTH}
        imageCoverValueTH={imageCoverTH}
        setImagePreviewTH={setImagePreviewTH}
        formDataTH={formDataTH}
        setFormDataTH={setFormDataTH}
        typeData="imageCoverTH"
      />

      <ImageUpload
        name="imageLogo"
        label="โลโก้โรงแรม"
        t={t}
        folderName="hotel"
        onFileChange={handleImageChangeTH}
        imageLogoValueTH={logoTH}
        setImagePreviewTH={setImagePreviewTH}
        formDataTH={formDataTH}
        setFormDataTH={setFormDataTH}
        typeData="imageLogoTH"
      />
      <ProvinceSelectTH />
      <PhoneInput name="phone" label="เบอร์โทรศัพท์" />
      <LinkContact name="linkContact" label="ลิงก์แฟนเพจ" placeholder="กรอกลิงก์แฟนเพจ" />
      <TagInput label="แท็กของโรงแรม" />
    </Form>
  );

  const renderFormEN = () => (
    <Form
      form={formEN}
      layout="vertical"
      onFinish={handleSubmit}
      onFinishFailed={onFinishFailed}
      onValuesChange={(changedValues, allValues) => {
        setFormDataEN(allValues);
      }}
    >
      <Row>
        <Col xs={23} sm={23} md={23} lg={11} xl={11} xxl={24}>
          <h3>ตัวอย่างการแสดงผล</h3>
          <HotelCard
            name={formDataEN?.name}
            companyName={formDataEN?.companyName}
            location={formDataEN?.province}
            phone={formDataEN?.phone}
            backgroundImage={imagePreviewEN?.imageCover || formDataEN?.imageCover}
            logo={imagePreviewEN?.imageLogo || formDataEN?.imageLogo}
            onClick={handleCardENClick}
          />
        </Col>
      </Row>
      <NameInput
        label="ชื่อเพจโรงแรม"
        name="name"
        requiredLabel="กรุณากรอกชื่อเพจโรงแรม"
        placeholder="กรอกชื่อเพจโรงแรม"
      />
      <NameInput
        label="ชื่อโรงแรม"
        name="companyName"
        requiredLabel="กรุณากรอกชื่อโรงแรม"
        placeholder="กรอกชื่อโรงแรม"
      />

      <ImageUpload
        name="imageCover"
        label="รูปภาพพื้นหลัง"
        t={t}
        folderName="hotel"
        onFileChange={handleImageChangeEN}
        setImagePreviewEN={setImagePreviewEN}
        imageCoverValueEN={imageCoverEN}
        formDataEN={formDataEN}
        setFormDataEN={setFormDataEN}
        typeData="imageCoverEN"
      />
      <ImageUpload
        name="imageLogo"
        label="โลโก้โรงแรม"
        t={t}
        folderName="hotel"
        onFileChange={handleImageChangeEN}
        imageLogoValueEN={logoEN}
        setImagePreviewEN={setImagePreviewEN}
        formDataEN={formDataEN}
        setFormDataEN={setFormDataEN}
        typeData="imageLogoEN"
      />
      <ProvinceSelectEN />
      <PhoneInput name="phone" label="เบอร์โทรศัพท์" />
      <LinkContact name="linkContact" label="ลิงก์แฟนเพจ" placeholder="กรอกลิงก์แฟนเพจ" />
      <TagInput label="แท็กของโรงแรม" />
    </Form>
  );

  if (loading) {
    return <OverlayLoading />;
  }

  if (loadingPage) {
    return <Loading />;
  }

  return (
    <AppLayout currentPage="หน้าแรก" getIconNav={<HomeOutlined style={{ fontSize: "24px" }} />} screens={screens}>
      <AdminLabel />
      <div style={{ maxWidth: "1200px", margin: "0 auto", padding: "24px" }}>
        <h1 style={{ fontSize: "24px", fontWeight: "bold", textAlign: "center", marginBottom: "24px" }}>
          แก้ไขข้อมูลโรงแรม
        </h1>
        <Row gutter={[16, 16]}>
          {/* ฝั่งซ้าย */}
          <Col xs={24} md={12}>
            <h3>ภาษาไทย</h3>
            {renderFormTH()}
          </Col>
          {/* ฝั่งขวา */}
          <Col xs={24} md={12}>
            <h3>ภาษาอังกฤษ</h3>
            {renderFormEN()}
          </Col>
        </Row>
        <Button loading={loading} type="primary" onClick={handleSubmit}>
          บันทึกข้อมูล
        </Button>
      </div>
    </AppLayout>
  );
};

export default EditHotelPage;
