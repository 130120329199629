import React from "react";
import { Col, Button, Space, Image, Typography } from "antd";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

const CategoryButton = ({ activeButton, handleButtonClick }) => {
  const { t } = useTranslation();
  const buttonData = [
    { type: "run", icon: "running-icon", label: t("sport-register-page.button-1") },
    { type: "bicycle", icon: "bicycle-icon", label: t("sport-register-page.button-2") },
    { type: "sport", icon: "medal-icon", label: t("sport-register-page.button-3") },
  ];
  const buttonDataAnother = [
    { type: "eventOrganizer", icon: "sport-duo-icon", label: t("home-page.button-9-1") },
    { type: "shirtShop", icon: "shirt-icon", label: t("home-page.button-9-2") },
    { type: "trophiesMedals", icon: "trophy-icon", label: t("home-page.button-9-3") },
    { type: "bibChip", icon: "medal-icon", label: t("home-page.button-9-4") },
  ];

  return (
    <>
      {["run", "bicycle", "sport"].includes(activeButton) ? (
        buttonData.map((item, index) => (
          <Col
            key={item.type}
            xs={8}
            sm={8}
            md={8}
            lg={4}
            xl={3}
            style={{
              backgroundColor: "#150E6E",
              padding: "8px",
              borderRadius: index === 0 ? "12px 0 0 12px" : index === 2 ? "0 12px 12px 0" : "0",
            }}
          >
            <Button
              style={{
                backgroundColor: activeButton === item?.type ? "#FFFFFF" : "transparent",
                color: activeButton === item?.type ? "#150E6E" : "#FFFFFF",
                width: "100%",
                height: "100%",
                borderRadius: "12px",
                border: "none",
                boxShadow: activeButton === item?.type ? "0px 4px 15px rgba(0, 0, 0, 0.15)" : "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => handleButtonClick(item?.type)}
            >
              <Space direction="vertical" align="center">
                <Image
                  preview={false}
                  src={activeButton === item.type ? `/${item?.icon}-purple.svg` : `/${item?.icon}-white.svg`}
                  style={{ width: "50px", height: "50px" }}
                />
                <Title level={5} style={{ margin: 0, color: activeButton === item?.type ? "#150E6E" : "#FFFFFF" }}>
                  {item?.label}
                </Title>
              </Space>
            </Button>
          </Col>
        ))
      ) : activeButton === "hotel" ? (
        <Col
          xs={{ span: 12, offset: 8 }}
          sm={{ span: 12, offset: 8 }}
          md={6}
          lg={{ span: 8, offset: 4 }}
          xl={{ span: 8, offset: 4 }}
          xxl={{ span: 9, offset: 3 }}
        >
          <Space direction="horizontal" align="center">
            <Image preview={false} src="/hotel-icon.svg" alt="Sport Icon 2" />
            <Typography.Title level={3} style={{ color: "#FFFFFF", margin: 0 }}>
              {t("home-page.button-2")}
            </Typography.Title>
          </Space>
        </Col>
      ) : activeButton === "carRent" ? (
        <Col
          xs={{ span: 12, offset: 8 }}
          sm={{ span: 12, offset: 8 }}
          md={6}
          lg={{ span: 8, offset: 4 }}
          xl={{ span: 8, offset: 4 }}
          xxl={{ span: 9, offset: 3 }}
        >
          <Space direction="horizontal" align="center">
            <Image preview={false} src="/car-icon.svg" alt="Sport Icon 2" />
            <Typography.Title level={3} style={{ color: "#FFFFFF", margin: 0 }}>
              {t("home-page.button-3")}
            </Typography.Title>
          </Space>
        </Col>
      ) : activeButton === "shuttle" ? (
        <Col
          xs={{ span: 12, offset: 8 }}
          sm={{ span: 12, offset: 8 }}
          md={6}
          lg={{ span: 8, offset: 4 }}
          xl={{ span: 8, offset: 4 }}
          xxl={{ span: 9, offset: 3 }}
        >
          <Space direction="horizontal" align="center">
            <Image preview={false} src="/taxi-icon.svg" alt="Sport Icon 2" />
            <Typography.Title level={3} style={{ color: "#FFFFFF", margin: 0 }}>
              {t("home-page.button-4")}
            </Typography.Title>
          </Space>
        </Col>
      ) : activeButton === "tour" ? (
        <Col
          xs={{ span: 12, offset: 8 }}
          sm={{ span: 12, offset: 8 }}
          md={6}
          lg={{ span: 8, offset: 4 }}
          xl={{ span: 8, offset: 4 }}
          xxl={{ span: 9, offset: 3 }}
        >
          <Space direction="horizontal" align="center">
            <Image preview={false} src="/packaging-icon.svg" alt="Sport Icon 2" />
            <Typography.Title level={3} style={{ color: "#FFFFFF", margin: 0 }}>
              {t("home-page.button-5")}
            </Typography.Title>
          </Space>
        </Col>
      ) : activeButton === "oneDayTrip" ? (
        <Col
          xs={{ span: 12, offset: 8 }}
          sm={{ span: 12, offset: 8 }}
          md={6}
          lg={{ span: 8, offset: 4 }}
          xl={{ span: 8, offset: 4 }}
          xxl={{ span: 9, offset: 3 }}
        >
          <Space direction="horizontal" align="center">
            <Image preview={false} src="/chair-umbrella-icon.svg" alt="Sport Icon 2" />
            <Typography.Title level={3} style={{ color: "#FFFFFF", margin: 0 }}>
              {t("home-page.button-6")}
            </Typography.Title>
          </Space>
        </Col>
      ) : activeButton === "evCharger" ? (
        <Col
          xs={{ span: 12, offset: 8 }}
          sm={{ span: 12, offset: 8 }}
          md={6}
          lg={{ span: 8, offset: 4 }}
          xl={{ span: 8, offset: 4 }}
          xxl={{ span: 9, offset: 3 }}
        >
          <Space direction="horizontal" align="center">
            <Image preview={false} src="/ev-charging-icon.svg" alt="Sport Icon 2" />
            <Typography.Title level={3} style={{ color: "#FFFFFF", margin: 0 }}>
              {t("home-page.button-7")}
            </Typography.Title>
          </Space>
        </Col>
      ) : activeButton === "shop" ? (
        <Col
          xs={{ span: 12, offset: 8 }}
          sm={{ span: 12, offset: 8 }}
          md={6}
          lg={{ span: 8, offset: 4 }}
          xl={{ span: 8, offset: 4 }}
          xxl={{ span: 9, offset: 3 }}
        >
          <Space direction="horizontal" align="center">
            <Image preview={false} src="/shop-icon.svg" alt="Sport Icon 2" />
            <Typography.Title level={3} style={{ color: "#2DB2AD", margin: 0 }}>
              {t("home-page.button-8")}
            </Typography.Title>
          </Space>
        </Col>
      ) : activeButton === "travel" ? (
        <Col
          xs={{ span: 22, offset: 2 }}
          sm={{ span: 20, offset: 4 }}
          md={{ span: 18, offset: 6 }}
          lg={{ span: 8, offset: 4 }}
          xl={{ span: 8, offset: 4 }}
          xxl={{ span: 9, offset: 3 }}
        >
          <Space direction="horizontal" align="center">
            <Image preview={false} src="/map-icon-white.svg" alt="Sport Icon 2" />
            <Typography.Title level={3} style={{ color: "#FFFFFF", margin: 0 }}>
              {t("home-page.button-10")}
            </Typography.Title>
          </Space>
        </Col>
      ) : activeButton === "calendarEvent" ? (
        <Col
          xs={{ span: 22, offset: 2 }}
          sm={{ span: 20, offset: 4 }}
          md={{ span: 18, offset: 6 }}
          lg={{ span: 8, offset: 4 }}
          xl={{ span: 8, offset: 4 }}
          xxl={{ span: 9, offset: 3 }}
        >
          <Space direction="horizontal" align="center">
            <Image width={150} preview={false} src="/calendar-icon-white.svg" alt="Sport Icon 2" />
            <Typography.Title level={3} style={{ color: "#FFFFFF", margin: 0 }}>
              {t("home-page.button-11")}
            </Typography.Title>
          </Space>
        </Col>
      ) : activeButton === "photographer" ? (
        <Col
          xs={{ span: 22, offset: 2 }}
          sm={{ span: 20, offset: 4 }}
          md={{ span: 18, offset: 6 }}
          lg={{ span: 8, offset: 4 }}
          xl={{ span: 8, offset: 4 }}
          xxl={{ span: 9, offset: 3 }}
        >
          <Space direction="horizontal" align="center">
            <Image width={150} preview={false} src="/photo-icon-white.svg" alt="Sport Icon 2" />
            <Typography.Title level={3} style={{ color: "#FFFFFF", margin: 0 }}>
              {t("home-page.button-12")}
            </Typography.Title>
          </Space>
        </Col>
      ) : activeButton === "spiritual" ? (
        <Col
          xs={{ span: 22, offset: 2 }}
          sm={{ span: 20, offset: 4 }}
          md={{ span: 18, offset: 6 }}
          lg={{ span: 8, offset: 4 }}
          xl={{ span: 8, offset: 4 }}
          xxl={{ span: 9, offset: 3 }}
        >
          <Space direction="horizontal" align="center">
            <Image width={150} preview={false} src="/mu-icon.svg" alt="Sport Icon 2" />
            <Typography.Title level={3} style={{ color: "#FFFFFF", margin: 0 }}>
              {t("home-page.button-13")}
            </Typography.Title>
          </Space>
        </Col>
      ) : activeButton === "food" ? (
        <Col
          xs={{ span: 22, offset: 2 }}
          sm={{ span: 20, offset: 4 }}
          md={{ span: 18, offset: 6 }}
          lg={{ span: 8, offset: 4 }}
          xl={{ span: 8, offset: 4 }}
          xxl={{ span: 9, offset: 3 }}
        >
          <Space direction="horizontal" align="center">
            <Image preview={false} src="/food-shop-icon-white.svg" alt="Sport Icon 2" />
            <Typography.Title level={3} style={{ color: "#FFFFFF", margin: 0 }}>
              {t("home-page.button-14")}
            </Typography.Title>
          </Space>
        </Col>
      ) : ["eventOrganizer", "shirtShop", "trophiesMedals", "bibChip"].includes(activeButton) ? (
        buttonDataAnother.map((item, index) => (
          <Col
            key={item.type}
            xs={12}
            sm={12}
            md={6}
            lg={3}
            xl={3}
            style={{
              backgroundColor: "#150E6E",
              padding: "8px",
              borderRadius: index === 0 ? "12px 0 0 12px" : index === 3 ? "0 12px 12px 0" : "0",
            }}
          >
            <Button
              style={{
                backgroundColor: activeButton === item?.type ? "#FFFFFF" : "transparent",
                color: activeButton === item?.type ? "#150E6E" : "#FFFFFF",
                width: "100%",
                height: "100%",
                borderRadius: "12px",
                border: "none",
                boxShadow: activeButton === item?.type ? "0px 4px 15px rgba(0, 0, 0, 0.15)" : "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => handleButtonClick(item?.type)}
            >
              <Space direction="vertical" align="center">
                <Image
                  preview={false}
                  src={activeButton === item.type ? `/${item?.icon}-purple.svg` : `/${item?.icon}-white.svg`}
                  style={{ width: "50px", height: "50px" }}
                />
                <Title level={5} style={{ margin: 0, color: activeButton === item?.type ? "#150E6E" : "#FFFFFF" }}>
                  {item?.label}
                </Title>
              </Space>
            </Button>
          </Col>
        ))
      ) : (
        <></>
      )}
    </>
  );
};

export default CategoryButton;
