import React from "react";
import { Row, Grid, Card, Typography, Flex, Button } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import RunningEventList from "../../../components/sport-register/running-event-list";
import { useNavigate } from "react-router-dom";
import MenuButton from "../../../components/menu-button";
import CategoryButton from "../../../components/sport-register/category-button";
import FormControl from "../../../components/sport-register/form-control";
import AppLayoutAdmin from "../../../layout/app-layout-admin";
import AdminLabel from "../../../components/admin/admin-label";
import AppLayout from "../../../layout/app-layout";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../context/auth-context";
import { useAppContext } from "../../../context/app-provider";
import { GetPublicRelations } from "../../../services/fetch-public-relations";
import RecommendCard from "../../home/recommend-card";

const { useBreakpoint } = Grid;

const TravelAdminPage = ({}) => {
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [activeButton, setActiveButton] = React.useState("travel");
  const [publicRelationsData, setPublicRelationsData] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [selectedProvince, setSelectedProvince] = React.useState("");
  const { userAdmin } = useAuth();
  const { language } = useAppContext();
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    const loadEvents = async () => {
      try {
        setLoading(true);
        const response = await GetPublicRelations("travel", language);

        const formattedData = Object.entries(response).map(([id, details]) => ({
          id,
          ...details,
        }));

        setPublicRelationsData(formattedData);
      } catch (error) {
        console.error("Error fetching public relations data:", error);
      } finally {
        setLoading(false);
      }
    };

    loadEvents();
  }, [language]);

  const filteredPublicRelationsData = publicRelationsData?.filter((publicRelations) => {
    return (
      (publicRelations?.companyName?.toLowerCase().includes(searchTerm) ||
        publicRelations?.name?.toLowerCase().includes(searchTerm) ||
        publicRelations?.tags?.some((tag) => tag.toLowerCase().includes(searchTerm))) &&
      (selectedProvince === "" || publicRelations.province === selectedProvince)
    );
  });

  const handleButtonClick = (type) => {
    setActiveButton(type);
    navigate(`/${language}/admin/${type}`);
  };

  return (
    <AppLayout currentPage="หน้าแรก" getIconNav={<HomeOutlined style={{ fontSize: "24px" }} />} screens={screens}>
      <RecommendCard t={t} navigate={navigate} />
      <div style={{ backgroundColor: "#5E72EB", padding: "24px" }}>
        <Row gutter={[16, 16]} align="middle">
          <CategoryButton t={t} activeButton={activeButton} handleButtonClick={handleButtonClick} />
          <FormControl
            t={t}
            screens={screens}
            setSearchTerm={setSearchTerm}
            setSelectedProvince={setSelectedProvince}
            activeButton={activeButton}
          />
        </Row>
      </div>
      <Flex justify="flex-end" align="center">
        <Button onClick={() => navigate(`/${language}/admin/add-travel`)}>เพิ่มแนะนำสถานที่ท่องเที่ยว</Button>
      </Flex>
      <RunningEventList
        loading={loading}
        userAdmin={userAdmin}
        publicRelationsData={filteredPublicRelationsData}
        setSearchTerm={setSearchTerm}
        setSelectedProvince={setSelectedProvince}
        t={t}
        activeButton={activeButton}
        screens={screens}
      />
    </AppLayout>
  );
};

export default TravelAdminPage;
