import React from "react";
import { Card, Typography, Avatar, Row, Col, Modal } from "antd";
import {
  EnvironmentOutlined,
  YoutubeOutlined,
  EditOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { Loading } from "../loading-spin";
import { ShowPublicRelations } from "../../services/fetch-public-relations";

const { Text } = Typography;

const TripCard = ({
  loading,
  id,
  name,
  companyName,
  location,
  backgroundImage,
  onClick,
  userAdmin,
  navigate,
  language,
  show,
}) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false); // State for Modal visibility
  const [visibilityState, setVisibilityState] = React.useState(show); // To hold current visibility state
  const [showEyeIcon, setShowEyeIcon] = React.useState(show); // To hold current visibility state

  if (loading) {
    return <Loading />;
  }

  const handleToggleVisibility = async (newState) => {
    try {
      const result = await ShowPublicRelations(id, "trip", newState); // เรียก API พร้อมสถานะใหม่
      setVisibilityState(newState); // Update visibility state
      setShowEyeIcon(newState); // Update showEyeIcon to reflect the new state
      setIsModalVisible(false); // Close the modal after update
    } catch (error) {
      console.error("Error while executing ShowPublicRelations:", error);
    }
  };

  const showModal = (newState) => {
    setIsModalVisible(true); // Open modal
    setVisibilityState(newState); // Set state to show the intended action
  };

  const handleCancel = () => {
    setIsModalVisible(false); // Close modal if cancelled
  };
  return (
    <>
      <Card
        onClick={onClick}
        hoverable
        cover={
          <img
            alt="example"
            src={backgroundImage || "/preview-icon.jpg"}
            style={{
              aspectRatio: "16/9",
              overflow: "hidden",
              width: "100%",
              height: "100%",
              minHeight: "150px",
              objectFit: "cover",
            }}
          />
        }
        actions={
          userAdmin
            ? [
                <EditOutlined
                  key="edit"
                  style={{ fontSize: "20px" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/${language}/admin/edit-trip/${id}`);
                  }}
                />,
                showEyeIcon ? (
                  <EyeInvisibleOutlined
                    key="eye"
                    style={{ fontSize: "20px", color: "green" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      showModal(false); // Show confirmation modal
                    }}
                  />
                ) : (
                  <EyeOutlined
                    key="eye-invisible"
                    style={{ fontSize: "20px", color: "gray" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      showModal(true); // Show confirmation modal
                    }}
                  />
                ),
              ]
            : undefined
        }
      >
        <Card.Meta
          title={
            <Typography.Title
              level={5}
              style={{
                textAlign: "center",
                marginBottom: "8px",
                wordWrap: "break-word",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {name}
            </Typography.Title>
          }
          description={
            <Row justify="start" gutter={[8, 8]}>
              <Col span={24}>
                <Text>
                  <EnvironmentOutlined style={{ fontSize: "18px", color: "red", marginRight: "4px" }} />
                  {location}
                </Text>
              </Col>
              <Col span={24}>
                <YoutubeOutlined style={{ fontSize: "18px", color: "red", marginRight: "4px" }} />
                <Text>{companyName}</Text>
              </Col>
            </Row>
          }
        />
      </Card>
      <Modal
        title="แน่ใจหรือไม่?"
        open={isModalVisible}
        onOk={() => handleToggleVisibility(visibilityState)}
        onCancel={handleCancel}
        okText="ยืนยัน"
        cancelText="ยกเลิก"
      >
        <p>ต้องการที่จะ {visibilityState ? "hide" : "show"} หรือไม่</p>
      </Modal>
    </>
  );
};

export default TripCard;
