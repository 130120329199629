import { Form, Select } from "antd";

const TagInput = ({ label }) => {
  return (
    <Form.Item label={label} name="tags">
      <Select mode="tags" style={{ width: "100%" }} placeholder="เพิ่มแท็ก" tokenSeparators={[","]} />
    </Form.Item>
  );
};

export default TagInput;
