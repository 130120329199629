import { Row, Col, Button, Space, Typography, Image } from "antd";
import { useAuth } from "../context/auth-context";
import { useAppContext } from "../context/app-provider";

const { Title } = Typography;

const MenuButton = ({
  t,
  screens,
  navigate,
  isSportRegisterPage,
  isHotelPage,
  isCarRent,
  isShuttlePage,
  isBookATour,
  isOneDayTrip,
  isEvChargerPage,
  isShopPage,
  isAnotherPage,
}) => {
  const { user, userAdmin } = useAuth();
  const { language } = useAppContext();

  const buttonStyles = [
    {
      backgroundColor: "#5E72EB",
      color: "#FFFFFF",
      width: "100%",
      height: "100%",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    }, // Sport Register
    {
      backgroundColor: "#150E6E",
      color: "#FFFFFF",
      width: "100%",
      height: "100%",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    }, // จองโรงแรม
    {
      backgroundColor: "#5E72EB",
      color: "#FFFFFF",
      width: "100%",
      height: "100%",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    }, // รถเช่า
    {
      backgroundColor: "#2DB2AD",
      color: "#FFFFFF",
      width: "100%",
      height: "100%",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    }, // รถรับส่ง
    {
      backgroundColor: "#FF9190",
      color: "#FFFFFF",
      width: "100%",
      height: "100%",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    }, // จองทัวร์
    {
      backgroundColor: "#FCBE95",
      color: "#FFFFFF",
      width: "100%",
      height: "100%",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    }, // One Day Trip
    {
      backgroundColor: "#EFE26A",
      color: "#FFFFFF",
      width: "100%",
      height: "100%",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    }, // EV Charger
    { color: "#FFFFFF", width: "100%", height: "100%", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" }, //  Shop
    { color: "#FFFFFF", width: "100%", height: "100%", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" }, // 4 Icon
  ];

  return (
    <>
      <Row gutter={[4, 4]} justify="space-around" style={{ margin: "20px 0px 0px 0px" }}>
        <Col
          xs={23}
          sm={17}
          md={12}
          lg={11}
          xl={11}
          xxl={4}
          style={{ padding: screens.xxl && isSportRegisterPage ? "0px 0px 0px 0px" : "0px 0px 20px 0px" }}
        >
          <Button
            type="text"
            style={{
              ...buttonStyles[0],
              borderRadius: screens.xxl && isSportRegisterPage ? "8px 8px 0px 0px" : "",
            }}
            onClick={() => navigate(userAdmin ? `/${language}/event/run` : `/${language}/sport-register/run`)}
            size="large"
          >
            <Space direction="vertical" align="center">
              <Image preview={false} src="/sport-icon.svg" alt="Sport Icon 1" />
              <Title level={5} style={{ margin: 0, color: "#FFFFFF" }}>
                {t("home-page.button-1")}
              </Title>
            </Space>
          </Button>
        </Col>
        <Col
          xs={7}
          sm={5}
          md={5}
          lg={4}
          xl={4}
          xxl={2}
          style={{ paddingBottom: screens.xxl && isHotelPage ? "0px" : "20px" }}
        >
          <Button
            type="text"
            style={{
              ...buttonStyles[1],
              borderRadius: screens.xxl && isHotelPage ? "8px 8px 0px 0px" : "",
            }}
            onClick={() => navigate(userAdmin ? `/${language}/admin/hotel` : `/${language}/hotel`)}
            // onClick={() => (window.location.href = "https://paipao.my.canva.site/hotel")}
            size="large"
          >
            <Space direction="vertical" align="center">
              <Image preview={false} src="/hotel-icon.svg" alt="Sport Icon 2" />
              <Title level={5} style={{ margin: 0, color: "#FFFFFF" }}>
                {t("home-page.button-2")}
              </Title>
            </Space>
          </Button>
        </Col>
        <Col
          xs={7}
          sm={5}
          md={5}
          lg={4}
          xl={4}
          xxl={2}
          style={{ paddingBottom: screens.xxl && isCarRent ? "0px" : "20px" }}
        >
          <Button
            type="text"
            style={{
              ...buttonStyles[2],
              borderRadius: screens.xxl && isCarRent ? "8px 8px 0px 0px" : "",
            }}
            onClick={() => navigate(userAdmin ? `/${language}/admin/car-rent` : `/${language}/car-rent`)}
            // onClick={() => (window.location.href = "https://paipao.my.canva.site/car")}
            size="large"
          >
            <Space direction="vertical" align="center">
              <Image preview={false} src="/car-icon.svg" alt="Sport Icon 3" />
              <Title level={5} style={{ margin: 0, color: "#FFFFFF" }}>
                {t("home-page.button-3")}
              </Title>
            </Space>
          </Button>
        </Col>
        {/* Add more buttons in a similar fashion */}
        <Col
          xs={7}
          sm={5}
          md={7}
          lg={4}
          xl={4}
          xxl={2}
          style={{ paddingBottom: screens.xxl && isShuttlePage ? "0px" : "20px" }}
        >
          <Button
            type="text"
            style={{
              ...buttonStyles[3],
              borderRadius: screens.xxl && isShuttlePage ? "8px 8px 0px 0px" : "",
            }}
            onClick={() => navigate(userAdmin ? `/${language}/admin/shuttle` : `/${language}/shuttle`)}
            // onClick={() => (window.location.href = "https://paipao.my.canva.site/taxi")}
            size="large"
          >
            <Space direction="vertical" align="center">
              <Image preview={false} src="/taxi-icon.svg" alt="Sport Icon 4" />
              <Title level={5} style={{ margin: 0, color: "#FFFFFF" }}>
                {t("home-page.button-4")}
              </Title>
            </Space>
          </Button>
        </Col>
        <Col
          xs={7}
          sm={5}
          md={7}
          lg={4}
          xl={4}
          xxl={2}
          style={{ paddingBottom: screens.xxl && isBookATour ? "0px" : "20px" }}
        >
          <Button
            type="text"
            style={{
              ...buttonStyles[4],
              borderRadius: screens.xxl && isBookATour ? "8px 8px 0px 0px" : "",
            }}
            onClick={() => navigate(userAdmin ? `/${language}/admin/tour` : `/${language}/tour`)}
            // onClick={() => (window.location.href = "https://paipao.my.canva.site/tour")}
            size="large"
          >
            <Space direction="vertical" align="center">
              <Image preview={false} src="/packaging-icon.svg" alt="Sport Icon 5" />
              <Title level={5} style={{ margin: 0, color: "#FFFFFF" }}>
                {t("home-page.button-5")}
              </Title>
            </Space>
          </Button>
        </Col>
        <Col
          xs={7}
          sm={5}
          md={7}
          lg={4}
          xl={4}
          xxl={2}
          style={{ paddingBottom: screens.xxl && isOneDayTrip ? "0px" : "20px" }}
        >
          <Button
            type="text"
            style={{
              ...buttonStyles[5],
              borderRadius: screens.xxl && isOneDayTrip ? "8px 8px 0px 0px" : "",
            }}
            onClick={() => navigate(userAdmin ? `/${language}/admin/trip` : `/${language}/trip`)}
            // onClick={() => (window.location.href = "https://paipao.my.canva.site/one-day-trip")}
            size="large"
          >
            <Space direction="vertical" align="center">
              <Image preview={false} src="/chair-umbrella-icon.svg" alt="Sport Icon 6" />
              <Title level={5} style={{ margin: 0, color: "#FFFFFF" }}>
                {t("home-page.button-6")}
              </Title>
            </Space>
          </Button>
        </Col>
        <Col
          xs={7}
          sm={5}
          md={5}
          lg={4}
          xl={4}
          xxl={2}
          style={{ paddingBottom: screens.xxl && isEvChargerPage ? "0px" : "20px" }}
        >
          <Button
            type="text"
            style={{
              ...buttonStyles[6],
              borderRadius: screens.xxl && isEvChargerPage ? "8px 8px 0px 0px" : "",
            }}
            onClick={() => navigate(userAdmin ? `/${language}/admin/ev-charger` : `/${language}/ev-charger`)}
            // onClick={() => (window.location.href = "https://paipao.my.canva.site/ev")}
            size="large"
          >
            <Space direction="vertical" align="center">
              <Image preview={false} src="/ev-charging-icon.svg" alt="Sport Icon 7" />
              <Title level={5} style={{ margin: 0, color: "#FFFFFF" }}>
                {t("home-page.button-7")}
              </Title>
            </Space>
          </Button>
        </Col>
        <Col
          xs={7}
          sm={5}
          md={5}
          lg={4}
          xl={4}
          xxl={2}
          style={{ paddingBottom: screens.xxl && isShopPage ? "0px" : "20px" }}
        >
          <Button
            type="text"
            style={{
              ...buttonStyles[7],
              borderRadius: screens.xxl && isShopPage ? "8px 8px 0px 0px" : "",
            }}
            onClick={() => navigate(userAdmin ? `/${language}/admin/shop` : `/${language}/shop`)}
            // onClick={() => (window.location.href = "https://paipao.my.canva.site/shop")}
            size="large"
          >
            <Space direction="vertical" align="center">
              <Image preview={false} src="/shop-icon.svg" alt="Sport Icon 8" />
              <Title level={5} style={{ margin: 0, color: "#2DB2AD" }}>
                {t("home-page.button-8")}
              </Title>
            </Space>
          </Button>
        </Col>
        <Col
          xs={15}
          sm={11}
          md={12}
          lg={7}
          xl={7}
          xxl={6}
          style={{ padding: screens.xxl && isAnotherPage ? "0px 0px 0px 0px" : "0px 0px 20px 0px" }}
        >
          <Button
            type="text"
            style={{
              ...buttonStyles[8],
              borderRadius: screens.xxl && isAnotherPage ? "8px 8px 0px 0px" : "",
            }}
            onClick={() =>
              navigate(userAdmin ? `/${language}/admin/eventOrganizer` : `/${language}/eventOrganizer`)
            }
            // onClick={() => (window.location.href = "https://paipao.my.canva.site/shop")}
            size="large"
          >
            <Space direction="vertical" size="small" align="center">
              <Row justify="center" align="center" gutter={[4, 4]}>
                <Col span={6}>
                  <Image preview={false} src="/sport-duo-icon.svg" alt="Sport Icon 9" />
                </Col>
                <Col span={6}>
                  <Image preview={false} src="/medal-icon.svg" alt="Sport Icon 10" />
                </Col>
                <Col span={12} style={{ textAlign: "start" }}>
                  {screens.xs ? (
                    <>
                      <Typography.Paragraph style={{ margin: 0 }}>{t("home-page.button-9-1")}</Typography.Paragraph>
                      <Typography.Paragraph style={{ margin: 0 }}>{t("home-page.button-9-2")}</Typography.Paragraph>
                    </>
                  ) : (
                    <>
                      <Title level={5} style={{ margin: 0 }}>
                        {t("home-page.button-9-1")}
                      </Title>
                      <Title level={5} style={{ margin: 0 }}>
                        {t("home-page.button-9-2")}
                      </Title>
                    </>
                  )}
                </Col>
              </Row>
              <Row justify="center" align="center" gutter={[4, 4]}>
                <Col span={6}>
                  <Image preview={false} src="/shirt-icon.svg" alt="Sport Icon 11" />
                </Col>
                <Col span={6}>
                  <Image preview={false} src="/trophy-icon.svg" alt="Sport Icon 12" />
                </Col>
                <Col span={12} style={{ textAlign: "start" }}>
                  {screens.xs ? (
                    <>
                      <Typography.Paragraph style={{ margin: 0 }}>{t("home-page.button-9-3")}</Typography.Paragraph>
                      <Typography.Paragraph style={{ margin: 0 }}>{t("home-page.button-9-4")}</Typography.Paragraph>
                    </>
                  ) : (
                    <>
                      <Title level={5} style={{ margin: 0 }}>
                        {t("home-page.button-9-3")}
                      </Title>
                      <Title level={5} style={{ margin: 0 }}>
                        {t("home-page.button-9-4")}
                      </Title>
                    </>
                  )}
                </Col>
              </Row>
            </Space>
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default MenuButton;
