import { Form, Input } from "antd";

const PhoneInput = ({ label, name }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: true, message: "กรุณากรอกชื่อ" }]}>
      <Input placeholder="เบอร์โทรศัพท์" />
    </Form.Item>
  );
};

export default PhoneInput;
