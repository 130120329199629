import React from "react";
import { Row, Col, Card, Image, Typography } from "antd";
import { useAuth } from "../../context/auth-context";
import { useAppContext } from "../../context/app-provider";

const { Paragraph } = Typography;

const RecommendCard = ({ t, navigate }) => {
  const { user, userAdmin } = useAuth();
  const { language } = useAppContext();

  return (
    <>
      <Row justify="center" gutter={[16, 16]} style={{ marginTop: "20px" }}>
        <Col xs={23} sm={23} md={23} lg={23} xl={8} xxl={8}>
          <Card
            hoverable
            size="small"
            style={{ borderRadius: "20px" }}
            onClick={() => navigate(userAdmin ? `/${language}/admin/travel` : `/${language}/travel`)}
          >
            <Row justify="center" align="middle">
              <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8} style={{ textAlign: "center" }}>
                <Image src="/map-icon.svg" preview={false} alt="Location Recommendation" />
                <Paragraph strong style={{ margin: 0, color: "#5E72EB" }}>
                  {t("home-page.button-10")}
                </Paragraph>
              </Col>
              <Col xs={12} sm={12} md={16} lg={16} xl={16} xxl={16} style={{ textAlign: "center" }}>
                <Image
                  src="/recommend-place.JPG"
                  preview={false}
                  width="100%"
                  alt="Location Recommendation"
                  style={{ maxWidth: "500px", height: "100%", borderRadius: "30px" }}
                />
              </Col>
            </Row>
          </Card>
        </Col>

        <Col xs={23} sm={23} md={23} lg={23} xl={8} xxl={8}>
          <Card
            hoverable
            size="small"
            style={{ borderRadius: "20px" }}
            onClick={() => navigate(userAdmin ? `/${language}/admin/calendarEvent` : `/${language}/calendarEvent`)}
          >
            <Row justify="center" align="middle">
              <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8} style={{ textAlign: "center" }}>
                <Image src="/calendar-icon.svg" preview={false} alt="Sport Calendar" />
                <Paragraph strong style={{ margin: 0, color: "#4FB7E5" }}>
                  {t("home-page.button-11")}
                </Paragraph>
              </Col>
              <Col xs={12} sm={12} md={16} lg={16} xl={16} xxl={16} style={{ textAlign: "center" }}>
                <Image
                  src="/recommend-calendar.JPG"
                  preview={false}
                  width="100%"
                  alt="Sport Calendar"
                  style={{ maxWidth: "500px", height: "100%", borderRadius: "30px" }}
                />
              </Col>
            </Row>
          </Card>
        </Col>

        <Col xs={23} sm={23} md={23} lg={23} xl={8} xxl={8}>
          <Card
            hoverable
            size="small"
            style={{ borderRadius: "20px" }}
            onClick={() => navigate(userAdmin ? `/${language}/admin/photographer` : `/${language}/photographer`)}
          >
            <Row justify="center" align="middle">
              <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8} style={{ textAlign: "center" }}>
                <Image src="/photo-icon.svg" preview={false} alt="Photo Grapher" />
                <Paragraph strong style={{ margin: 0, color: "#70ACB6" }}>
                  {t("home-page.button-12")}
                </Paragraph>
              </Col>
              <Col xs={12} sm={12} md={16} lg={16} xl={16} xxl={16} style={{ textAlign: "center" }}>
                <Image
                  src="/recommend-photo.JPG"
                  preview={false}
                  width="100%"
                  alt="Photo Grapher"
                  style={{ maxWidth: "500px", height: "100%", borderRadius: "30px" }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row justify="center" gutter={[16, 16]} style={{ marginTop: "20px", marginBottom: "20px" }}>
        <Col xs={23} sm={23} md={23} lg={23} xl={8} xxl={8}>
          <Card
            hoverable
            size="small"
            style={{ borderRadius: "20px" }}
            onClick={() => navigate(userAdmin ? `/${language}/admin/spiritual` : `/${language}/spiritual`)}
          >
            <Row justify="center" align="middle">
              <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8} style={{ textAlign: "center" }}>
                <Image src="/mu-icon.svg" preview={false} alt="Location Recommendation" />
                <Paragraph strong style={{ margin: 0, color: "#4B3221" }}>
                  {t("home-page.button-13")}
                </Paragraph>
              </Col>
              <Col xs={12} sm={12} md={16} lg={16} xl={16} xxl={16} style={{ textAlign: "center" }}>
                <Image
                  src="/recommend-mu.JPG"
                  preview={false}
                  width="100%"
                  alt="Mu Location"
                  style={{ maxWidth: "500px", height: "100%", borderRadius: "30px" }}
                />
              </Col>
            </Row>
          </Card>
        </Col>

        <Col xs={23} sm={23} md={23} lg={23} xl={8} xxl={8}>
          <Card
            hoverable
            size="small"
            style={{ borderRadius: "20px" }}
            onClick={() => navigate(userAdmin ? `/${language}/admin/food` : `/${language}/food`)}
          >
            <Row justify="center" align="middle">
              <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8} style={{ textAlign: "center" }}>
                <Image src="/food-shop-icon.svg" preview={false} alt="Food Promotion" />
                <Paragraph strong style={{ margin: 0, color: "#FF9190" }}>
                  {t("home-page.button-14")}
                </Paragraph>
              </Col>
              <Col xs={12} sm={12} md={16} lg={16} xl={16} xxl={16} style={{ textAlign: "center" }}>
                <Image
                  src="/recommend-food.JPG"
                  preview={false}
                  width="100%"
                  alt="Food Promotion"
                  style={{ maxWidth: "500px", height: "100%", borderRadius: "30px" }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default RecommendCard;
