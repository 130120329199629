import React, { useState } from "react";
import { Row, Col, Image, Typography, Card, Grid, Modal } from "antd";
import MenuButton from "../components/menu-button";
import { HomeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import AppLayout from "../layout/app-layout";
import { useTranslation } from "react-i18next";
import RecommendCard from "./home/recommend-card";

const { useBreakpoint } = Grid;
const { Title, Paragraph } = Typography;

const Home = ({ user }) => {
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Use useState to manage the Canva embed URL
  // const [show, setShow] = useState("https://www.canva.com/design/DAGSyDEgmIo/bj94pmY67XBHc_NVDfRbBQ/view?embed");
  // const [color, setColor] = useState("#5E72EB");

  return (
    <>
      <AppLayout
        currentPage={t("app-header.title-1")}
        user={user}
        GetIconNav={<HomeOutlined style={{ fontSize: "24px" }} />}
        screens={screens}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Image src="/home-banner.svg" preview={false} width="100%" alt="Main Content Image" />
          </Col>
        </Row>

        {/* Menu Button */}
        <MenuButton screens={screens} navigate={navigate} t={t} />
        <RecommendCard t={t} navigate={navigate} />
        {/* <div
      style={{
        position: "relative",
        width: "100%",
        height: 0,
        paddingTop: "56.25%", // Adjust the aspect ratio if needed
        paddingBottom: 0,
        boxShadow: "0 2px 8px 0 rgba(63,69,81,0.16)",
        marginTop: "1.6em",
        marginBottom: "0.9em",
        overflow: "hidden",
        borderRadius: "8px",
        willChange: "transform",
      }}
    >
      <iframe
        loading="lazy"
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          border: "none",
          padding: 0,
          margin: 0,
        }}
        src="https://paipao.my.canva.site/travel"
        allowFullScreen
        title="Travel Site"
      />
    </div> */}
      </AppLayout>
    </>
  );
};

export default Home;
