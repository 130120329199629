import React from "react";
import { useRoutes } from "react-router-dom";
import { Navigate } from "react-router-dom";
import LoginPage from "../pages/login-page";
import RegisterPage from "../pages/register-page";
import Home from "../pages/home-page";
import Profile from "../pages/profile-page";
import LoginAdminPage from "../pages/login-admin-page";
import HomeAdmin from "../pages/home-admin-page";
import RegisterRunFormPage from "../pages/home/sport-register/run/register-run-form-page";
import DetailEventRunPage from "../pages/home/sport-register/run/detail-event-run-page";
import MemberCheckRunPage from "../pages/home/sport-register/run/member-check-run-page";
import SportRegisterRunPage from "../pages/home/sport-register/run/sport-register-run-page";
import SportRegisterBikePage from "../pages/home/sport-register/bike/sport-register-bike-page";
import SportRegisterSportPage from "../pages/home/sport-register/sport/sport-register-sport-page";
import ManageMemberPage from "../pages/admin/manage-member-page";
import ManageCheckAndUpdatePage from "../pages/admin/sport-register/run/manage-check-and-update-run-page";
import HotelPage from "../pages/home/hotel/hotel-page";
import CarRentPage from "../pages/home/car-rent/car-rent-page";
import ShuttlePage from "../pages/home/shuttle/shuttle-page";
import BookATourPage from "../pages/home/tour/tour-page";
import OneDayTripPage from "../pages/home/trip/trip-page";
import EvChargerPage from "../pages/home/Ev-charger/ev-charger-page";
import ShopPage from "../pages/home/shop/shop-page";
import UserRoutes from "./user-routes";
import AdminRoutes from "./admin-routes";
import AuthRoute from "./auth-routes";
import AdminEventDetailPage from "../pages/admin/sport-register/run/detail-event-run-admin-page";
import SportRegisterRunAdminPage from "../pages/admin/sport-register/run/sport-register-run-admin-page";
import SportRegisterBikeAdminPage from "../pages/admin/sport-register/bike/sport-register-bike-admin-page";
import SportRegisterSportAdminPage from "../pages/admin/sport-register/sport/sport-register-sport-admin-page";
import HotelAdminPage from "../pages/admin/hotel/hotel-page";
import CarRentAdminPage from "../pages/admin/car-rent/car-rent-page";
import ShuttleAdminPage from "../pages/admin/shuttle/shuttle-page";
import BookATourAdminPage from "../pages/admin/tour/tour-page";
import OneDayTripAdminPage from "../pages/admin/trip/trip-page";
import EvChargerAdminPage from "../pages/admin/Ev-charger/ev-charger-page";
import ShopAdminPage from "../pages/admin/shop/shop-page";
import EventDetail from "../pages/home/my-register-event-page";
import OrderShirtPage from "../pages/home/my-order-shirt-page";
import AddEvent from "../pages/admin/sport-register/run/add-run-event";
import DetailEventBikePage from "../pages/home/sport-register/bike/detail-event-bike-page";
import MemberCheckBikePage from "../pages/home/sport-register/bike/member-check-bike-page";
import RegisterBikeFormPage from "../pages/home/sport-register/bike/register-bike-fom-page";
import ManageCheckAndUpdateOrderShirtRunPage from "../pages/admin/sport-register/run/manage-check-and-update-order-shirt-run-page";
import ManageCheckAndUpdateBikePage from "../pages/admin/sport-register/bike/manage-check-and-update-bike-page";
import AdminEventDetailBikePage from "../pages/admin/sport-register/bike/detail-event-bike-admin-page";
import AddHotelPage from "../pages/admin/hotel/add-hotel-page";
import AddTourPage from "../pages/admin/tour/add-tour-page";
import EditHotelPage from "../pages/admin/hotel/edit-hotel-page";
import AddCarRentPage from "../pages/admin/car-rent/add-car-rent-page";
import EditCarRentPage from "../pages/admin/car-rent/edit-car-rent-page";
import AddOneDayTripAdminPage from "../pages/admin/trip/add-trip-page";
import AddEvChargerAdminPage from "../pages/admin/Ev-charger/add-ev-charger-page";
import AddShopPage from "../pages/admin/shop/add-shop-page";
import EventOrganizerAdminPage from "../pages/admin/another/event-organizer-page";
import BibChipAdminPage from "../pages/admin/another/bib-chip-page";
import ShirtShopAdminPage from "../pages/admin/another/shirt-shop-page";
import TrophiesMedalsAdminPage from "../pages/admin/another/trophies-medals-page";
import AddEventOrganizerPage from "../pages/admin/another/add-event-organizer-page";
import AddBibChipPage from "../pages/admin/another/add-bib-chip-page";
import AddShirtShopPage from "../pages/admin/another/add-shirt-shop-page";
import AddTrophiesMedalsPage from "../pages/admin/another/add-trophies-medals-page";
import AddShuttlePage from "../pages/admin/shuttle/add-shuttle-page";
import EventOrganizerPage from "../pages/home/another/event-organizer-page";
import ShirtShopPage from "../pages/home/another/shirt-shop-page";
import BibChipPage from "../pages/home/another/bib-chip-page";
import TrophiesMedalsPage from "../pages/home/another/trophies-medals-page";
import EditTourPage from "../pages/admin/tour/edit-tour-page";
import EditShuttlePage from "../pages/admin/shuttle/edit-shuttle-page";
import EditOneDayTripPage from "../pages/admin/trip/edit-trip-page";
import EditEvChargerPage from "../pages/admin/Ev-charger/edit-ev-charger-page";
import EditShopPage from "../pages/admin/shop/edit-shop-page";
import EditEventOrganizerPage from "../pages/admin/another/edit-event-organizer-page";
import EditShirtShopPage from "../pages/admin/another/edit-shirt-shop-page";
import EditTrophiesMedalsPage from "../pages/admin/another/edit-trophies-medals-page";
import EditBibChipPage from "../pages/admin/another/edit-bib-chip-page";
import TravelAdminPage from "../pages/admin/travel/travel-page";
import AddTravelAdminPage from "../pages/admin/travel/add-travel-page";
import CalendarEventAdminPage from "../pages/admin/calendar-event/calendar-event-page";
import AddCalendarEventPage from "../pages/admin/calendar-event/add-calendar-event-page";
import PhotographerAdminPage from "../pages/admin/photographer/photographer-page";
import AddPhotographerPage from "../pages/admin/photographer/add-photographer-page";
import SpiritualAdminPage from "../pages/admin/spiritual/spiritual-page";
import AddSpiritualAdminPage from "../pages/admin/spiritual/add-spiritual-page";
import FoodAdminPage from "../pages/admin/food/food-page";
import AddFoodPage from "../pages/admin/food/add-food-page";
import TravelPage from "../pages/home/travel/travel-page";
import CalendarEventPage from "../pages/home/calendar-event/calendar-event-page";
import PhotographerPage from "../pages/home/photographer/photographer-page";
import SpiritualPage from "../pages/home/spiritual/spiritual-page";
import FoodPage from "../pages/home/food/food-page";
import EditCalendarEventPage from "../pages/admin/calendar-event/edit-calendar-event-page";
import EditTravelPage from "../pages/admin/travel/edit-travel-page";
import EditPhotographerPage from "../pages/admin/photographer/edit-photographer-page";
import EditSpiritualPage from "../pages/admin/spiritual/edit-spiritual-page";
import EditFoodPage from "../pages/admin/food/edit-food-page";

const Routes = () => {
  const element = useRoutes([
    {
      path: "/",
      element: <Navigate to="/th" replace />, // Redirect จาก root ไป "/th"
    },
    {
      path: "/:lang", // เพิ่มระดับของ path สำหรับภาษา
      children: [
        { path: "", element: <Home /> },
        {
          element: <AuthRoute />,
          children: [
            { path: "login", element: <LoginPage /> },
            { path: "register", element: <RegisterPage /> },
            { path: "admin", element: <LoginAdminPage /> },
          ],
        },
        {
          element: <UserRoutes />,
          children: [
            { path: "", element: <Home /> },
            { path: "profile", element: <Profile /> },
            { path: "profile/my-event", element: <EventDetail /> },
            { path: "profile/my-order", element: <OrderShirtPage /> },
            { path: "sport-register/run/register/:id", element: <RegisterRunFormPage /> },
            { path: "sport-register/bicycle/register/:id", element: <RegisterBikeFormPage /> },
            { path: "sport-register/run/member/:id", element: <MemberCheckRunPage /> },
            { path: "sport-register/bicycle/member/:id", element: <MemberCheckBikePage /> },
          ],
        },
        { path: "sport-register/run", element: <SportRegisterRunPage /> },
        { path: "sport-register/bicycle", element: <SportRegisterBikePage /> },
        { path: "sport-register/sport", element: <SportRegisterSportPage /> },
        { path: "hotel", element: <HotelPage /> },
        { path: "car-rent", element: <CarRentPage /> },
        { path: "shuttle", element: <ShuttlePage /> },
        { path: "tour", element: <BookATourPage /> },
        { path: "trip", element: <OneDayTripPage /> },
        { path: "ev-charger", element: <EvChargerPage /> },
        { path: "shop", element: <ShopPage /> },
        { path: "eventOrganizer", element: <EventOrganizerPage /> },
        { path: "shirtShop", element: <ShirtShopPage /> },
        { path: "trophiesMedals", element: <TrophiesMedalsPage /> },
        { path: "bibChip", element: <BibChipPage /> },
        { path: "travel", element: <TravelPage /> },
        { path: "calendarEvent", element: <CalendarEventPage /> },
        { path: "photographer", element: <PhotographerPage /> },
        { path: "spiritual", element: <SpiritualPage /> },
        { path: "food", element: <FoodPage /> },
        { path: "sport-register/run/detail/:id", element: <DetailEventRunPage /> },
        { path: "sport-register/bicycle/detail/:id", element: <DetailEventBikePage /> },
      ],
    },
    {
      path: "/:lang", // แยก route สำหรับ admin โดยใช้ path เดียวกันเพื่อการจัดการในระดับภาษาทั้งหมด
      element: <AdminRoutes />,
      children: [
        { path: "home", element: <HomeAdmin /> },
        { path: "event/run", element: <SportRegisterRunAdminPage /> },
        { path: "event/bicycle", element: <SportRegisterBikeAdminPage /> },
        { path: "event/sport", element: <SportRegisterSportAdminPage /> },
        { path: "event/run/add-event", element: <AddEvent /> },
        { path: "event/run/manage-check-update/member/:id", element: <ManageCheckAndUpdatePage /> },
        { path: "event/run/manage-check-update/order-shirt/:id", element: <ManageCheckAndUpdateOrderShirtRunPage /> },
        { path: "event/run/detail/:id", element: <AdminEventDetailPage /> },
        { path: "event/bicycle/manage-check-update/member/:id", element: <ManageCheckAndUpdateBikePage /> },
        {
          path: "event/bicycle/manage-check-update/order-shirt/:id",
          element: <ManageCheckAndUpdateOrderShirtRunPage />,
        },
        { path: "event/bicycle/detail/:id", element: <AdminEventDetailBikePage /> },
        { path: "admin/hotel", element: <HotelAdminPage /> },
        { path: "admin/add-hotel", element: <AddHotelPage /> },
        { path: "admin/edit-hotel/:id", element: <EditHotelPage /> },
        { path: "admin/car-rent", element: <CarRentAdminPage /> },
        { path: "admin/add-car-rent", element: <AddCarRentPage /> },
        { path: "admin/edit-car-rent/:id", element: <EditCarRentPage /> },
        { path: "admin/shuttle", element: <ShuttleAdminPage /> },
        { path: "admin/add-shuttle", element: <AddShuttlePage /> },
        { path: "admin/edit-shuttle/:id", element: <EditShuttlePage /> },
        { path: "admin/tour", element: <BookATourAdminPage /> },
        { path: "admin/edit-tour/:id", element: <EditTourPage /> },
        { path: "admin/add-tour", element: <AddTourPage /> },
        { path: "admin/trip", element: <OneDayTripAdminPage /> },
        { path: "admin/add-trip", element: <AddOneDayTripAdminPage /> },
        { path: "admin/edit-trip/:id", element: <EditOneDayTripPage /> },
        { path: "admin/ev-charger", element: <EvChargerAdminPage /> },
        { path: "admin/add-ev-charger", element: <AddEvChargerAdminPage /> },
        { path: "admin/edit-ev-charger/:id", element: <EditEvChargerPage /> },
        { path: "admin/shop", element: <ShopAdminPage /> },
        { path: "admin/add-shop", element: <AddShopPage /> },
        { path: "admin/edit-shop/:id", element: <EditShopPage /> },
        { path: "admin/eventOrganizer", element: <EventOrganizerAdminPage /> },
        { path: "admin/add-eventOrganizer", element: <AddEventOrganizerPage /> },
        { path: "admin/edit-eventOrganizer/:id", element: <EditEventOrganizerPage /> },
        { path: "admin/shirtShop", element: <ShirtShopAdminPage /> },
        { path: "admin/add-shirtShop", element: <AddShirtShopPage /> },
        { path: "admin/edit-shirtShop/:id", element: <EditShirtShopPage /> },
        { path: "admin/trophiesMedals", element: <TrophiesMedalsAdminPage /> },
        { path: "admin/add-trophiesMedals", element: <AddTrophiesMedalsPage /> },
        { path: "admin/edit-trophiesMedals/:id", element: <EditTrophiesMedalsPage /> },
        { path: "admin/bibChip", element: <BibChipAdminPage /> },
        { path: "admin/add-bibChip", element: <AddBibChipPage /> },
        { path: "admin/edit-bibChip/:id", element: <EditBibChipPage /> },
        { path: "admin/travel", element: <TravelAdminPage /> },
        { path: "admin/add-travel", element: <AddTravelAdminPage /> },
        { path: "admin/edit-travel/:id", element: <EditTravelPage /> },
        { path: "admin/calendarEvent", element: <CalendarEventAdminPage /> },
        { path: "admin/add-calendarEvent", element: <AddCalendarEventPage /> },
        { path: "admin/edit-calendarEvent/:id", element: <EditCalendarEventPage /> },
        { path: "admin/photographer", element: <PhotographerAdminPage /> },
        { path: "admin/add-photographer", element: <AddPhotographerPage /> },
        { path: "admin/edit-photographer/:id", element: <EditPhotographerPage /> },
        { path: "admin/spiritual", element: <SpiritualAdminPage /> },
        { path: "admin/add-spiritual", element: <AddSpiritualAdminPage /> },
        { path: "admin/edit-spiritual/:id", element: <EditSpiritualPage /> },
        { path: "admin/food", element: <FoodAdminPage /> },
        { path: "admin/add-food", element: <AddFoodPage /> },
        { path: "admin/edit-food/:id", element: <EditFoodPage /> },
      ],
    },
    // { path: "*", element: <NotFoundPage /> },
  ]);

  return element;
};

export default Routes;
