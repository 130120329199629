import React from "react";
import { Card, Typography, Avatar, Row, Col, Grid, Modal } from "antd";
import {
  EnvironmentOutlined,
  PhoneOutlined,
  EditOutlined,
  EyeOutlined,
  ContainerOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { ShowPublicRelations } from "../../services/fetch-public-relations";
import { Loading } from "../loading-spin";

const { Text } = Typography;

const ShopCard = ({
  loading,
  activeButton,
  id,
  name,
  companyName,
  location,
  phone,
  backgroundImage,
  logo,
  onClick,
  userAdmin,
  navigate,
  language,
  show,
}) => {
  const { xs, sm, md, lg, xl, xxl } = Grid.useBreakpoint();

  const avatarSize = xxl ? 150 : xl ? 150 : lg ? 150 : md ? 200 : sm ? 200 : xs ? 150 : 200; // Set size based on breakpoints
  const [isModalVisible, setIsModalVisible] = React.useState(false); // State for Modal visibility
  const [visibilityState, setVisibilityState] = React.useState(show); // To hold current visibility state
  const [showEyeIcon, setShowEyeIcon] = React.useState(show); // To hold current visibility state

  if (loading) {
    return <Loading />;
  }

  const handleToggleVisibility = async (newState) => {
    try {
      const result = await ShowPublicRelations(id, "shop", newState); // เรียก API พร้อมสถานะใหม่
      setVisibilityState(newState); // Update visibility state
      setShowEyeIcon(newState); // Update showEyeIcon to reflect the new state
      setIsModalVisible(false); // Close the modal after update
    } catch (error) {
      console.error("Error while executing ShowPublicRelations:", error);
    }
  };

  const showModal = (newState) => {
    setIsModalVisible(true); // Open modal
    setVisibilityState(newState); // Set state to show the intended action
  };

  const handleCancel = () => {
    setIsModalVisible(false); // Close modal if cancelled
  };

  return (
    <>
      <Card
        onClick={onClick}
        hoverable
        cover={
          <div style={{ position: "relative" }}>
            <img
              alt="example"
              src={backgroundImage || "/preview-icon.jpg"} // ใช้ default image หากไม่มี backgroundImage
              style={{
                borderRadius: "8px 8px 0 0",
                aspectRatio: "16/9",
                overflow: "hidden",
                width: "100%",
                height: "100%",
                minHeight: "150px",
                objectFit: "cover",
              }}
            />
            <Avatar
              src={logo || "/preview-icon.jpg"} // ใช้ default logo หากไม่มีโลโก้
              size={avatarSize}
              style={{
                position: "absolute",
                bottom: "-48px",
                left: "50%",
                transform: "translateX(-50%)",
                border: "4px solid rgba(255, 255, 255, 1)",
              }}
            />
          </div>
        }
        actions={
          userAdmin
            ? [
                <EditOutlined
                  key="edit"
                  style={{ fontSize: "20px" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/${language}/admin/edit-${activeButton}/${id}`);
                  }}
                />,
                showEyeIcon ? (
                  <EyeInvisibleOutlined
                    key="eye"
                    style={{ fontSize: "20px", color: "green" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      showModal(false); // Show confirmation modal
                    }}
                  />
                ) : (
                  <EyeOutlined
                    key="eye-invisible"
                    style={{ fontSize: "20px", color: "gray" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      showModal(true); // Show confirmation modal
                    }}
                  />
                ),
              ]
            : undefined
        }
      >
        <Card.Meta
          title={
            <Typography.Title
              level={5}
              style={{
                textAlign: "center",
                marginBottom: "8px",
                wordWrap: "break-word",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              {companyName}
            </Typography.Title>
          }
          description={
            <Row justify="start" gutter={[8, 8]}>
              <Col span={24}>
                <Text>
                  <PhoneOutlined style={{ color: "blue", marginRight: "4px" }} />
                  {phone}
                </Text>
              </Col>
              <Col span={24}>
                <Text>
                  <EnvironmentOutlined style={{ color: "red", marginRight: "4px" }} />
                  {location}
                </Text>
              </Col>
              <Col span={24}>
                <ContainerOutlined style={{ color: "green", marginRight: "4px" }} />
                <Text>{name}</Text>
              </Col>
            </Row>
          }
        />
      </Card>
      <Modal
        title="แน่ใจหรือไม่?"
        open={isModalVisible}
        onOk={() => handleToggleVisibility(visibilityState)}
        onCancel={handleCancel}
        okText="ยืนยัน"
        cancelText="ยกเลิก"
      >
        <p>ต้องการที่จะ {visibilityState ? "hide" : "show"} หรือไม่</p>
      </Modal>
    </>
  );
};

export default ShopCard;
