// Loading.js
import React from "react";
import { Modal, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export const Loading = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
    </div>
  );
};

export const OverlayLoading = () => {
  return (
    <div
      style={{
        position: "fixed", // ใช้ fixed เพื่อให้ครอบคลุมทั้งหน้าจอ
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.3)", // สีดำโปร่งใส
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999, // ให้อยู่ด้านบนสุด
        color: "white", // ข้อความและไอคอนเป็นสีขาว
      }}
    >
      <Spin
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 64,
              color: "white", // ให้ไอคอนมีสีขาว
            }}
            spin
          />
        }
      />
    </div>
  );
};
