import React from "react";
import { Card, Grid } from "antd";
import MenuButton from "../components/menu-button";
import { HomeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import AdminLabel from "../components/admin/admin-label";
import AppLayoutAdmin from "../layout/app-layout-admin";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../context/app-provider";
import AppLayout from "../layout/app-layout";
import RecommendCard from "./home/recommend-card";

const { useBreakpoint } = Grid;

const HomeAdmin = ({}) => {
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const { language } = useAppContext();
  const { t } = useTranslation();

  const gridStyle = {
    width: "100%",
    textAlign: "start",
    cursor: "pointer",
  };

  return (
    <AppLayout currentPage="หน้าแรก" getIconNav={<HomeOutlined style={{ fontSize: "24px" }} />} screens={screens}>
      {/* Menu Button */}
      {/* <AdminLabel t={t} screens={screens} /> */}
      <MenuButton t={t} screens={screens} navigate={navigate} />
      <RecommendCard t={t} navigate={navigate} />
    </AppLayout>
  );
};

export default HomeAdmin;
