import React from "react";
import dayjs from "dayjs";
import "dayjs/locale/th";
import { Form, Button, message, DatePicker, Grid, Row, Col, InputNumber } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import NameInput from "../../../components/form-public-relations/name-input";
import ImageUpload from "../../../components/form-public-relations/image-input";
import { ProvinceSelectEN, ProvinceSelectTH } from "../../../components/form-public-relations/province-select";
import TagInput from "../../../components/form-public-relations/tag-input";
import { useTranslation } from "react-i18next";
import AppLayout from "../../../layout/app-layout";
import AdminLabel from "../../../components/admin/admin-label";
import PhoneInput from "../../../components/form-public-relations/phone-input";
import LinkContact from "../../../components/form-public-relations/link-contact";
import { CreatePublicRelations, uploadImages } from "../../../services/fetch-public-relations";
import { useAppContext } from "../../../context/app-provider";
import { useNavigate } from "react-router-dom";
import CalendarEventCard from "../../../components/admin/calendar-event-card";
import { OverlayLoading } from "../../../components/loading-spin";

const AddCalendarEventPage = () => {
  const { language } = useAppContext();
  const navigate = useNavigate();
  const [formTH] = Form.useForm();
  const [formEN] = Form.useForm();
  const [formDataTH, setFormDataTH] = React.useState({});
  const [formDataEN, setFormDataEN] = React.useState({});
  const [imagePreviewTH, setImagePreviewTH] = React.useState({});
  const [imagePreviewEN, setImagePreviewEN] = React.useState({});
  const [date, setDate] = React.useState("");
  const { t } = useTranslation();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [loading, setLoading] = React.useState(false);
  const fileNameCoverTH = `publicRelationsImage/calendarEvent/cover_th`;
  const fileNameCoverEN = `publicRelationsImage/calendarEvent/cover_en`;

  const handleOnlyDate = (dates, dateStrings) => {
    if (dates) {
      const formattedDate = dayjs(dateStrings, "YYYY-MM-DD").format("DD/MM/YYYY");
      setDate(formattedDate);
      setFormDataTH((prevData) => ({ ...prevData, date: formattedDate }));
      setFormDataEN((prevData) => ({ ...prevData, date: formattedDate }));
    } else {
      setDate("");
      setFormDataTH((prevData) => ({ ...prevData, date: "" }));
      setFormDataEN((prevData) => ({ ...prevData, date: "" }));
    }
  };

  const handleCardTHClick = () => {
    window.open(formDataTH?.linkContact, "_blank");
  };

  const handleCardENClick = () => {
    window.open(formDataEN?.linkContact, "_blank");
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      // Validate both forms and retrieve their values
      const [thValues, enValues] = await Promise.all([formTH.validateFields(), formEN.validateFields()]);

      const fileData = [
        { file: thValues.imageCover, fileName: fileNameCoverTH },
        { file: enValues.imageCover, fileName: fileNameCoverEN },
      ];

      const responseImageUrl = await uploadImages("calendarEvent", fileData);

      if (responseImageUrl) {
        const processKeys = (data) =>
          Object.fromEntries(
            Object.entries(data).map(([key, value]) => [
              key.replace(/_ca\d+$/, ""), // ตัด _h0000005 หรือ _hตามด้วยตัวเลขออก
              value,
            ])
          );

        const updatedResponseImageUrl = processKeys(responseImageUrl);

        const dataToSend = {
          th: {
            ...thValues,
            imageCover: updatedResponseImageUrl[fileNameCoverTH],
            date: date,
            show: true,
          },
          en: {
            ...enValues,
            imageCover: updatedResponseImageUrl[fileNameCoverEN],
            date: date,
            show: true,
          },
        };

        const response = await CreatePublicRelations("calendarEvent", dataToSend);

        if (response) {
          message.success("บันทึกข้อมูลสำเร็จ!");
          navigate(`/${language}/admin/calendarEvent`);
        } else {
          throw new Error("เกิดข้อผิดพลาดในการบันทึกข้อมูล");
        }
      } else {
        throw new Error("เกิดข้อผิดพลาดในการอัปโหลดรูปภาพ");
      }
    } catch (error) {
      console.error("Validation or API error:", error);
      message.error(error.message || "กรุณาตรวจสอบข้อมูลให้ครบถ้วน");
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    message.error("กรุณากรอกข้อมูลให้ครบถ้วน");
  };

  const handleImageChangeTH = ({ name, imageURL, rawData }) => {
    setImagePreviewTH((prevData) => ({
      ...prevData,
      [name]: imageURL,
    }));

    formTH.setFieldsValue({
      [name]: rawData,
    });
  };

  const handleImageChangeEN = ({ name, imageURL, rawData }) => {
    setImagePreviewEN((prevData) => ({
      ...prevData,
      [name]: imageURL,
    }));

    formEN.setFieldsValue({
      [name]: rawData,
    });
  };

  const renderFormTH = () => (
    <Form
      form={formTH}
      layout="vertical"
      onFinish={handleSubmit}
      onFinishFailed={onFinishFailed}
      onValuesChange={(changedValues, allValues) => {
        setFormDataTH(allValues);
      }}
    >
      <Row>
        <Col xs={23} sm={23} md={23} lg={11} xl={11} xxl={24}>
          <h3>ตัวอย่างการแสดงผล</h3>
          <CalendarEventCard
            name={formDataTH.name}
            location={formDataTH.province}
            date={date}
            backgroundImage={imagePreviewTH.imageCover}
            onClick={handleCardTHClick}
          />
        </Col>
      </Row>
      <NameInput
        label="ชื่อรายการกีฬา"
        name="name"
        requiredLabel="กรุณากรอกชื่อรายการกีฬา"
        placeholder="กรอกชื่อรายการกีฬา"
      />
      <ImageUpload
        name="imageCover"
        label="รูปภาพพื้นหลัง"
        t={t}
        folderName="hotel"
        onFileChange={handleImageChangeTH}
      />
      <ProvinceSelectTH />
      <LinkContact name="linkContact" label="ลิงก์ประชาสัมพันธ์" placeholder="กรอกลิงก์ประชาสัมพันธ์" />
      <TagInput label="แท็กของรายการกีฬา" />
      <Form.Item label="วันที่เริ่ม" name="date" rules={[{ required: true, message: "กรุณาเลือกวันที่เริ่ม" }]}>
        <DatePicker onChange={handleOnlyDate} />
      </Form.Item>
    </Form>
  );

  const renderFormEN = () => (
    <Form
      form={formEN}
      layout="vertical"
      onFinish={handleSubmit}
      onFinishFailed={onFinishFailed}
      onValuesChange={(changedValues, allValues) => {
        setFormDataEN(allValues);
      }}
    >
      <Row>
        <Col xs={23} sm={23} md={23} lg={11} xl={11} xxl={24}>
          <h3>ตัวอย่างการแสดงผล</h3>
          <CalendarEventCard
            name={formDataEN.name}
            location={formDataEN.province}
            phone={formDataEN.phone}
            date={date}
            backgroundImage={imagePreviewEN.imageCover}
            onClick={handleCardENClick}
          />
        </Col>
      </Row>
      <NameInput
        label="ชื่อรายการกีฬา"
        name="name"
        requiredLabel="กรุณากรอกชื่อรายการกีฬา"
        placeholder="กรอกชื่อรายการกีฬา"
      />
      <ImageUpload name="imageCover" label="รูปภาพพื้นหลัง" t={t} onFileChange={handleImageChangeEN} />
      <ProvinceSelectEN />
      <LinkContact name="linkContact" label="ลิงก์ประชาสัมพันธ์" placeholder="กรอกลิงก์ประชาสัมพันธ์" />
      <TagInput label="แท็กของรายการกีฬา" />
    </Form>
  );

  if (loading) {
    return <OverlayLoading />;
  }

  return (
    <AppLayout currentPage="หน้าแรก" getIconNav={<HomeOutlined style={{ fontSize: "24px" }} />} screens={screens}>
      <AdminLabel />
      <div style={{ maxWidth: "1200px", margin: "0 auto", padding: "24px" }}>
        <h1 style={{ fontSize: "24px", fontWeight: "bold", textAlign: "center", marginBottom: "24px" }}>
          เพิ่มช่างภาพ
        </h1>
        <Row gutter={[16, 16]}>
          {/* ฝั่งซ้าย */}
          <Col xs={24} md={12}>
            <h3>ภาษาไทย</h3>
            {renderFormTH()}
          </Col>
          {/* ฝั่งขวา */}
          <Col xs={24} md={12}>
            <h3>ภาษาอังกฤษ</h3>
            {renderFormEN()}
          </Col>
        </Row>
        <Button type="primary" onClick={handleSubmit}>
          บันทึกข้อมูล
        </Button>
      </div>
    </AppLayout>
  );
};

export default AddCalendarEventPage;
