import { Form, Input } from "antd";

const NameInput = ({ label, name, requiredLabel, placeholder, value }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: true, message: requiredLabel }]}>
      <Input placeholder={placeholder} defaultValue={value} />
    </Form.Item>
  );
};

export default NameInput;
