import React from "react";
import dayjs from "dayjs";
import "dayjs/locale/th";
import { Form, Button, message, Input, Grid, Row, Col, Checkbox, Space, DatePicker } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import NameInput from "../../../components/form-public-relations/name-input";
import ImageUpload from "../../../components/form-public-relations/image-input";
import { ProvinceSelectEN, ProvinceSelectTH } from "../../../components/form-public-relations/province-select";
import TagInput from "../../../components/form-public-relations/tag-input";
import { useTranslation } from "react-i18next";
import AppLayout from "../../../layout/app-layout";
import AdminLabel from "../../../components/admin/admin-label";
import ShopCard from "../../../components/admin/shop-card";
import PhoneInput from "../../../components/form-public-relations/phone-input";
import LinkContact from "../../../components/form-public-relations/link-contact";
import { EditUploadImages, UpdatePublicRelations, uploadImages } from "../../../services/fetch-public-relations";
import { useNavigate, useParams } from "react-router-dom";
import { fetchEventById } from "../../../services/fetch-events";
import { useAppContext } from "../../../context/app-provider";
import { Loading, OverlayLoading } from "../../../components/loading-spin";
import CalendarEventCard from "../../../components/admin/calendar-event-card";

const EditCalendarEventPage = () => {
  const navigate = useNavigate();
  const [formTH] = Form.useForm();
  const [formEN] = Form.useForm();
  const [formDataTH, setFormDataTH] = React.useState({});
  const [formDataEN, setFormDataEN] = React.useState({});
  const [imagePreviewTH, setImagePreviewTH] = React.useState({});
  const [imagePreviewEN, setImagePreviewEN] = React.useState({});
  const [imageCoverTH, setImageCoverTH] = React.useState({});
  const [imageCoverEN, setImageCoverEN] = React.useState({});
  const [date, setDate] = React.useState("");
  const { language } = useAppContext();
  const [loading, setLoading] = React.useState(false);
  const [loadingPage, setLoadingPage] = React.useState(false);
  const { t } = useTranslation();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const { id } = useParams();
  const fileNameCoverTH = `publicRelationsImage/calendarEvent/cover_th_${id}`;
  const fileNameCoverEN = `publicRelationsImage/calendarEvent/cover_en_${id}`;

  React.useEffect(() => {
    setLoadingPage(true);
    const getEventDataThai = async () => {
      const { eventData } = await fetchEventById(id, "th");
      setFormDataTH(eventData);
      setImageCoverTH(eventData.imageCover);
      setLoadingPage(false);
    };
    const getEventDataEng = async () => {
      const { eventData } = await fetchEventById(id, "en");
      setFormDataEN(eventData);
      setImageCoverEN(eventData.imageCover);
      setLoadingPage(false);
    };

    getEventDataThai();
    getEventDataEng();
  }, [id, language]);

  React.useEffect(() => {
    if (formDataEN) {
      formEN.setFieldsValue(formDataEN);
    }
    if (formDataTH) {
      formTH.setFieldsValue(formDataTH);
    }
  }, [formDataEN, formDataTH]);

  const handleOnlyDate = (dates, dateStrings) => {
    if (dates) {
      const formattedDate = dayjs(dateStrings, "YYYY-MM-DD").format("DD/MM/YYYY");
      setDate(formattedDate);
      setFormDataTH((prevData) => ({ ...prevData, date: formattedDate }));
      setFormDataEN((prevData) => ({ ...prevData, date: formattedDate }));
    } else {
      setDate("");
      setFormDataTH((prevData) => ({ ...prevData, date: "" }));
      setFormDataEN((prevData) => ({ ...prevData, date: "" }));
    }
  };

  const handleCardTHClick = () => {
    window.open(formDataTH?.linkContact, "_blank");
  };

  const handleCardENClick = () => {
    window.open(formDataEN?.linkContact, "_blank");
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const displayCardTH = formDataTH?.show;
      const displayCardEN = formDataEN?.show;
      const [enValues, thValues] = await Promise.all([formEN?.validateFields(), formTH?.validateFields()]);

      const hasFileChanged = (newFile, oldFile) => {
        if (!newFile && !oldFile) return false;
        if (!newFile || !oldFile) return true;

        if (newFile instanceof File && oldFile instanceof File) {
          return (
            newFile.name !== oldFile.name ||
            newFile.size !== oldFile.size ||
            newFile.lastModified !== oldFile.lastModified
          );
        } else if (typeof newFile === "string" && typeof oldFile === "string") {
          return newFile !== oldFile;
        }
        return false;
      };

      const filesToUpload = [
        {
          condition: hasFileChanged(thValues.imageCover, imageCoverTH),
          file: thValues.imageCover,
          fileName: fileNameCoverTH,
        },
        {
          condition: hasFileChanged(enValues.imageCover, imageCoverEN),
          file: enValues.imageCover,
          fileName: fileNameCoverEN,
        },
      ].filter(({ condition }) => condition);

      if (filesToUpload.length > 0) {
        const uploadResponse = await EditUploadImages(
          "calendarEvent",
          filesToUpload.map(({ file, fileName }) => ({ file, fileName }))
        );

        if (!uploadResponse) throw new Error("เกิดข้อผิดพลาดในการอัปโหลดรูปภาพ");


        // Process and map uploaded URLs
        const updatedResponseImageUrl = Object.fromEntries(
          Object.entries(uploadResponse).map(([key, value]) => [
            key.replace(`_ca${id}\\d+$/`, ""), // Remove suffix
            value,
          ])
        );

        const dataToSend = {
          th: {
            ...thValues,
            imageCover: updatedResponseImageUrl[fileNameCoverTH] || imageCoverTH,
            show: displayCardTH,
          },
          en: {
            ...enValues,
            imageCover: updatedResponseImageUrl[fileNameCoverEN] || imageCoverEN,
            show: displayCardEN,
          },
        };

        const response = await UpdatePublicRelations(id, "calendarEvent", dataToSend);

        if (response) {
          message.success("บันทึกข้อมูลสำเร็จ!");
          navigate(`/${language}/admin/calendarEvent`);
        } else {
          message.error("เกิดข้อผิดพลาดในการบันทึกข้อมูล");
        }
      } else {
        // ถ้าไม่มีการเปลี่ยนแปลงรูปภาพ
        const dataToSendNoImage = {
          th: { ...thValues, show: displayCardTH },
          en: { ...enValues, show: displayCardEN },
        };

        const response = await UpdatePublicRelations(id, "calendarEvent", dataToSendNoImage);

        if (response) {
          message.success("บันทึกข้อมูลสำเร็จ!");
          navigate(`/${language}/admin/calendarEvent`);
        } else {
          message.error("เกิดข้อผิดพลาดในการบันทึกข้อมูล");
        }
      }
      setLoading(false);
    } catch (error) {
      console.error("Validation or API error:", error);
      message.error(error.message || "กรุณาตรวจสอบข้อมูลให้ครบถ้วน");
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    message.error("กรุณากรอกข้อมูลให้ครบถ้วน");
  };

  const handleImageChangeTH = ({ name, imageURL, rawData }) => {
    setImagePreviewTH((prevData) => ({
      ...prevData,
      [name]: imageURL,
    }));

    formTH.setFieldsValue({
      [name]: rawData,
    });
  };

  const handleImageChangeEN = ({ name, imageURL, rawData }) => {
    setImagePreviewEN((prevData) => ({
      ...prevData,
      [name]: imageURL,
    }));

    formEN.setFieldsValue({
      [name]: rawData,
    });
  };

  const renderFormTH = () => (
    <Form
      form={formTH}
      layout="vertical"
      onFinish={handleSubmit}
      onFinishFailed={onFinishFailed}
      onValuesChange={(changedValues, allValues) => {
        setFormDataTH(allValues);
      }}
    >
      <Row>
        <Col xs={23} sm={23} md={23} lg={11} xl={11} xxl={24}>
          <h3>ตัวอย่างการแสดงผล</h3>
          <CalendarEventCard
            name={formDataTH.name}
            location={formDataTH.province}
            date={formDataTH?.date || date}
            backgroundImage={imagePreviewTH.imageCover || formDataTH?.imageCover}
            onClick={handleCardTHClick}
          />
        </Col>
      </Row>
      <NameInput
        label="ชื่อรายการกีฬา"
        name="name"
        requiredLabel="กรุณากรอกชื่อรายการกีฬา"
        placeholder="กรอกชื่อรายการกีฬา"
      />
      <ImageUpload
        name="imageCover"
        label="รูปภาพพื้นหลัง"
        t={t}
        folderName="travel"
        onFileChange={handleImageChangeTH}
        imageCoverValueTH={imageCoverTH}
        setImagePreviewTH={setImagePreviewTH}
        formDataTH={formDataTH}
        setFormDataTH={setFormDataTH}
        typeData="imageCoverTH"
      />
      <ProvinceSelectTH />
      <LinkContact name="linkContact" label="ลิงก์ประชาสัมพันธ์" placeholder="กรอกลิงก์ประชาสัมพันธ์" />
      <TagInput label="แท็กของรายการกีฬา" />
      <Form.Item
        label="หมดเขตรับสมัคร "
        name="date"
        rules={[{ required: true, message: "กรุณาเลือกวันหมดเขตรับสมัคร " }]}
        getValueProps={(value) => ({
          value: value ? dayjs(value, "DD/MM/YYYY") : null,
        })}
      >
        <DatePicker onChange={handleOnlyDate} />
      </Form.Item>
    </Form>
  );

  const renderFormEN = () => (
    <Form
      form={formEN}
      layout="vertical"
      onFinish={handleSubmit}
      onFinishFailed={onFinishFailed}
      onValuesChange={(changedValues, allValues) => {
        setFormDataEN(allValues);
      }}
    >
      <Row>
        <Col xs={23} sm={23} md={23} lg={11} xl={11} xxl={24}>
          <h3>ตัวอย่างการแสดงผล</h3>
          <CalendarEventCard
            name={formDataEN.name}
            location={formDataEN.province}
            phone={formDataEN.phone}
            date={formDataEN?.date || date}
            backgroundImage={imagePreviewEN.imageCover || formDataEN?.imageCover}
            onClick={handleCardENClick}
          />
        </Col>
      </Row>
      <NameInput
        label="ชื่อรายการกีฬา"
        name="name"
        requiredLabel="กรุณากรอกชื่อรายการกีฬา"
        placeholder="กรอกชื่อรายการกีฬา"
      />
      <ImageUpload
        name="imageCover"
        label="รูปภาพพื้นหลัง"
        t={t}
        onFileChange={handleImageChangeEN}
        setImagePreviewEN={setImagePreviewEN}
        imageCoverValueEN={imageCoverEN}
        formDataEN={formDataEN}
        setFormDataEN={setFormDataEN}
        typeData="imageCoverEN"
      />
      <ProvinceSelectEN />
      <LinkContact name="linkContact" label="ลิงก์ประชาสัมพันธ์" placeholder="กรอกลิงก์ประชาสัมพันธ์" />
      <TagInput label="แท็กของรายการกีฬา" />
    </Form>
  );

  if (loading) {
    return <OverlayLoading />;
  }

  if (loadingPage) {
    return <Loading />;
  }

  return (
    <AppLayout currentPage="หน้าแรก" getIconNav={<HomeOutlined style={{ fontSize: "24px" }} />} screens={screens}>
      <AdminLabel />
      <div style={{ maxWidth: "1200px", margin: "0 auto", padding: "24px" }}>
        <h1 style={{ fontSize: "24px", fontWeight: "bold", textAlign: "center", marginBottom: "24px" }}>
          แก้ไขข้อมูลปฏิทินกิจกรรม
        </h1>
        <Row gutter={[16, 16]}>
          {/* ฝั่งซ้าย */}
          <Col xs={24} md={12}>
            <h3>ภาษาไทย</h3>
            {renderFormTH()}
          </Col>
          {/* ฝั่งขวา */}
          <Col xs={24} md={12}>
            <h3>ภาษาอังกฤษ</h3>
            {renderFormEN()}
          </Col>
        </Row>
        <Button type="primary" onClick={handleSubmit}>
          บันทึกข้อมูล
        </Button>
      </div>
    </AppLayout>
  );
};

export default EditCalendarEventPage;
