import React from "react";
import { Form, Button, message, Grid, Row, Col } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import NameInput from "../../../components/form-public-relations/name-input";
import ImageUpload from "../../../components/form-public-relations/image-input";
import { ProvinceSelectEN, ProvinceSelectTH } from "../../../components/form-public-relations/province-select";
import TagInput from "../../../components/form-public-relations/tag-input";
import { useTranslation } from "react-i18next";
import AppLayout from "../../../layout/app-layout";
import AdminLabel from "../../../components/admin/admin-label";
import TripCard from "../../../components/admin/trip-card";
import LinkContact from "../../../components/form-public-relations/link-contact";
import { CreatePublicRelations, uploadImages } from "../../../services/fetch-public-relations";
import { useAppContext } from "../../../context/app-provider";
import { useNavigate } from "react-router-dom";
import { OverlayLoading } from "../../../components/loading-spin";

const AddOneDayTripAdminPage = () => {
  const { language } = useAppContext();
  const navigate = useNavigate();
  const [formTH] = Form.useForm();
  const [formEN] = Form.useForm();
  const [formDataTH, setFormDataTH] = React.useState({});
  const [formDataEN, setFormDataEN] = React.useState({});
  const [imagePreviewTH, setImagePreviewTH] = React.useState({});
  const [imagePreviewEN, setImagePreviewEN] = React.useState({});
  const { t } = useTranslation();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [loading, setLoading] = React.useState(false);
  const fileNameCoverTH = `publicRelationsImage/oneDayTrip/cover_th`;
  const fileNameCoverEN = `publicRelationsImage/oneDayTrip/cover_en`;

  const handleCardTHClick = () => {
    window.open(formDataTH?.linkContact, "_blank");
  };

  const handleCardENClick = () => {
    window.open(formDataEN?.linkContact, "_blank");
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      // Validate both forms and retrieve their values
      const [thValues, enValues] = await Promise.all([formTH.validateFields(), formEN.validateFields()]);
      const fileData = [
        { file: thValues.imageCover, fileName: fileNameCoverTH },
        { file: enValues.imageCover, fileName: fileNameCoverEN },
      ];

      const responseImageUrl = await uploadImages("oneDayTrip", fileData);

      if (responseImageUrl) {
        const processKeys = (data) => {
          const processedData = {};
          for (const key in data) {
            const newKey = key.replace(/_o\d+$/, ""); // ตัด _h0000005 หรือ _hตามด้วยตัวเลขออก
            processedData[newKey] = data[key];
          }
          return processedData;
        };
        const updatedResponseImageUrl = processKeys(responseImageUrl);

        const dataToSend = {
          th: {
            ...thValues,
            imageCover: updatedResponseImageUrl[fileNameCoverTH],
            show: true,
          },
          en: {
            ...enValues,
            imageCover: updatedResponseImageUrl[fileNameCoverEN],
            show: true,
          },
        };

        const response = await CreatePublicRelations("oneDayTrip", dataToSend);

        if (response) {
          setLoading(false);
          navigate(`/${language}/admin/trip`);
          message.success("บันทึกข้อมูลสำเร็จ!");
        } else {
          message.error("เกิดข้อผิดพลาดในการบันทึกข้อมูล");
        }
      } else {
        message.error("เกิดข้อผิดพลาดในการอัปโหลดรูปภาพ");
      }
    } catch (error) {
      console.error("Validation or API error:", error);
      message.error("กรุณาตรวจสอบข้อมูลให้ครบถ้วน");
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    message.error("กรุณากรอกข้อมูลให้ครบถ้วน");
  };

  const handleImageChangeTH = ({ name, imageURL, rawData }) => {
    setImagePreviewTH((prevData) => ({
      ...prevData,
      [name]: imageURL,
    }));

    formTH.setFieldsValue({
      [name]: rawData,
    });
  };

  const handleImageChangeEN = ({ name, imageURL, rawData }) => {
    setImagePreviewEN((prevData) => ({
      ...prevData,
      [name]: imageURL,
    }));

    formEN.setFieldsValue({
      [name]: rawData,
    });
  };

  const renderFormTH = () => (
    <Form
      form={formTH}
      layout="vertical"
      onFinish={handleSubmit}
      onFinishFailed={onFinishFailed}
      onValuesChange={(changedValues, allValues) => {
        setFormDataTH(allValues);
      }}
    >
      <Row>
        <Col xs={23} sm={23} md={23} lg={11} xl={11} xxl={24}>
          <h3>ตัวอย่างการแสดงผล</h3>
          <TripCard
            name={formDataTH.name}
            companyName={formDataTH.companyName}
            location={formDataTH.province}
            backgroundImage={imagePreviewTH.imageCover}
            onClick={handleCardTHClick}
          />
        </Col>
      </Row>
      <NameInput
        label="ชื่อชื่อหัวข้อ"
        name="name"
        requiredLabel="กรุณากรอกชื่อชื่อหัวข้อ"
        placeholder="กรอกชื่อชื่อหัวข้อ"
      />
      <NameInput
        label="ชื่อเชอะแนล"
        name="companyName"
        requiredLabel="กรุณากรอกชื่อเชอะแนล"
        placeholder="กรอกชื่อเชอะแนล"
      />
      <ImageUpload
        name="imageCover"
        label="รูปภาพพื้นหลัง"
        t={t}
        folderName="hotel"
        onFileChange={handleImageChangeTH}
      />
      {/* <ImageUpload name="imageLogo" label="โลโก้โรงแรม" t={t} folderName="hotel" onFileChange={handleImageChangeTH} /> */}
      <ProvinceSelectTH />
      <LinkContact name="linkContact" label="ลิงก์เชอะแนล" placeholder="กรอกลิงก์เชอะแนล" />
      <TagInput label="แท็กของหัวข้อ" />
    </Form>
  );

  const renderFormEN = () => (
    <Form
      form={formEN}
      layout="vertical"
      onFinish={handleSubmit}
      onFinishFailed={onFinishFailed}
      onValuesChange={(changedValues, allValues) => {
        setFormDataEN(allValues);
      }}
    >
      <Row>
        <Col xs={23} sm={23} md={23} lg={11} xl={11} xxl={24}>
          <h3>ตัวอย่างการแสดงผล</h3>
          <TripCard
            name={formDataEN.name}
            companyName={formDataEN.companyName}
            location={formDataEN.province}
            backgroundImage={imagePreviewEN.imageCover}
            logo={imagePreviewEN.imageLogo}
            onClick={handleCardENClick}
          />
        </Col>
      </Row>
      <NameInput label="ชื่อหัวข้อ" name="name" requiredLabel="กรุณากรอกชื่อหัวข้อ" placeholder="กรอกชื่อหัวข้อ" />
      <NameInput
        label="ชื่อเชอะแนล"
        name="companyName"
        requiredLabel="กรุณากรอกชื่อเชอะแนล"
        placeholder="กรอกชื่อเชอะแนล"
      />
      <ImageUpload name="imageCover" label="รูปภาพพื้นหลัง" t={t} onFileChange={handleImageChangeEN} />
      {/* <ImageUpload name="imageLogo" label="โลโก้โรงแรม" t={t} onFileChange={handleImageChangeEN} /> */}
      <ProvinceSelectEN />
      <LinkContact name="linkContact" label="ลิงก์เชอะแนล" placeholder="กรอกลิงก์เชอะแนล" />
      <TagInput label="แท็กของหัวข้อ" />
    </Form>
  );

  if (loading) {
    return <OverlayLoading />;
  }

  return (
    <AppLayout currentPage="หน้าแรก" getIconNav={<HomeOutlined style={{ fontSize: "24px" }} />} screens={screens}>
      <AdminLabel />
      <div style={{ maxWidth: "1200px", margin: "0 auto", padding: "24px" }}>
        <h1 style={{ fontSize: "24px", fontWeight: "bold", textAlign: "center", marginBottom: "24px" }}>
          แก้ข้อมูลโรงแรม
        </h1>
        <Row gutter={[16, 16]}>
          {/* ฝั่งซ้าย */}
          <Col xs={24} md={12}>
            <h3>ภาษาไทย</h3>
            {renderFormTH()}
          </Col>
          {/* ฝั่งขวา */}
          <Col xs={24} md={12}>
            <h3>ภาษาอังกฤษ</h3>
            {renderFormEN()}
          </Col>
        </Row>
        <Button type="primary" onClick={handleSubmit}>
          บันทึกข้อมูล
        </Button>
      </div>
    </AppLayout>
  );
};

export default AddOneDayTripAdminPage;
